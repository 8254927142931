import { useContext, useEffect, useState } from 'react';
import { IDisplayAppointments } from '../../container/Termine/Termine';
import AlertContext from '../../context/alertContext';
import LoginContext from '../../context/loginContext';
import useScreenResolution from '../../hooks/useScreenResolution';
import { getAppointmentsOfPatient, structureAppointments } from '../../services/fhir/FHIRAppointment';
import { IAppointment, getStickyPosition } from '../../utils/appointmentUtils';
import AppointmentCard from '../Cards/AppointmentCard';
import { StickyTitle } from './StyledHeader';

enum Scheduled {
    Past = 0,
    Cancelled,
}

export const PastAppointments = (props: IDisplayAppointments) => {
    const { state } = useContext(LoginContext);
    const { alertdispatch } = useContext(AlertContext);

    const screenSize = useScreenResolution();
    const stickyPosition = getStickyPosition(screenSize.width);

    const [structuredAppointments, setStructuredAppointments] = useState<any[]>();

    const getAppointments = async () => {
        const response = await getAppointmentsOfPatient(state, alertdispatch);
        if (response) {
            let apt: IAppointment[] = response.data.entry;

            if (apt === undefined) {
                apt = [];
            }

            if (apt) {
                apt.sort((a, b) => (a.resource.start < b.resource.start ? 1 : -1));
                setStructuredAppointments(structureAppointments(apt));
            }
        }
    };

    function PastAppointmentsTitle(category: Scheduled) {
        if (structuredAppointments !== undefined) {
            return (
                <StickyTitle stickyBreakpoint={stickyPosition}>
                    {stickyPosition === '160px' ? (
                        <h4>{structuredAppointments[1][category].key}</h4>
                    ) : (
                        <h3>{structuredAppointments[1][category].key}</h3>
                    )}
                </StickyTitle>
            );
        }
        return <></>;
    }

    function PastAppointmentsData(category: Scheduled) {
        if (structuredAppointments !== undefined) {
            const pastAppointments = structuredAppointments[1][category].data.map(
                (data: IAppointment, index: number) => (
                    <div key={index}>
                        <div style={{ width: 'auto', height: 'auto' }}>
                            <AppointmentCard {...data} type="past" showChatIcon={false} />
                        </div>
                    </div>
                ),
            );
            return pastAppointments;
        }
        return <></>;
    }

    useEffect(() => {
        getAppointments();
    }, [state.activeProfile]);

    useEffect(() => {
        if (props.refreshAppointments) {
            getAppointments();
            props.resetRefresh();
        }
    }, [props.refreshAppointments]);

    return (
        <div>
            {PastAppointmentsTitle(Scheduled.Past)}
            {PastAppointmentsData(Scheduled.Past)}
            {PastAppointmentsTitle(Scheduled.Cancelled)}
            {PastAppointmentsData(Scheduled.Cancelled)}
        </div>
    );
};

export default PastAppointments;
