import { useContext, useEffect, useRef, useState } from 'react';
import { Form, Image, Row } from 'react-bootstrap';
import Col from 'react-bootstrap/esm/Col';
import Lottie from 'react-lottie';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { Paths } from '../../Routes';
import {
    CenterDiv,
    CenterPositionFixedDiv,
    CheckInCenterDiv,
    IconDiv,
    RightDiv,
    SpaceBetweenDiv,
    VerticalCenterFlexDiv,
} from '../../components/Div/StyledDiv';
import CheckInContext from '../../context/checkInContext';
import ScanUtils, { BarcodeScanCallback } from '../../hooks/barcodeKeyHandler';
import loadingSplashWait from '../../lotties/splashscreenWait.json';
import {
    checkAppointment,
    getAppProperties,
    getOTPFromAppID,
    getURLToCall,
    interpreteKVParams,
} from '../../services/RestServices';
import { getQueryParams } from '../../utils/urlUtils';
import { INeuerAccountWL } from '../NeuerAccount/NeuerAccountWL';

import { BiBarcodeReader } from 'react-icons/bi';
import { ThemeContext } from 'styled-components';
import ReadCardButton from '../../components/Buttons/ReadCardButton';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import useScreenResolution from '../../hooks/useScreenResolution';
import { showAlertMessage } from '../../utils/alertHandling';
import AppPropsContext from '../../context/appPropsContext';
import { Divider } from '@material-ui/core';
import StyledButton from '../../components/Buttons/StyledButton';
import MyTooltip from '../../components/Tooltip/MyTooltip';
import { RiLogoutBoxRLine } from 'react-icons/ri';

export interface ICheckInSplashscreen {
    wpName?: string;
    reason?: string;
    tileUEB?: boolean;
    tileOBU?: boolean; //Onlinebuchung
    tilePAN?: boolean; //Praxisanmeldung
    tileTUE?: boolean; //Terminueberpruefung
}

export interface IURLToCallResponse {
    state: string;
    url: string;
    eGK: string;
    kvBarcode: string;
    birthday: string;
    city: string;
    country: string;
    firstName: string;
    gender: string;
    street: string;
    houseNumber: string;
    nameLast: string;
    zip: string;
    insuranceNr: string;
    insuranceName: string;
    language;
    error?: any;
    info?: any;
}

interface IVisibleTiles {
    tileUeberweisung: boolean;
    tileOnlinebuchung: boolean;
    tilePraxisanmeldung: boolean;
    tileTerminueberpruefung: boolean;
    fromOnlinebuchung: boolean;
}

interface ICheckedAppointmentData {
    userId: string;
    t0: string;
    otp: string;
    reason: string;
}

const CheckInSplashscreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const navtype = useNavigationType();
    const hashParams: ICheckInSplashscreen = getQueryParams(location.search);
    const { checkindispatch } = useContext(CheckInContext);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [brandingBytes, setBrandingBytes] = useState('');
    const { apstate } = useContext(AppPropsContext);
    const [visibleTiles, setVisibleTiles] = useState<IVisibleTiles>({
        tileUeberweisung: false,
        tileOnlinebuchung: false,
        tilePraxisanmeldung: false,
        tileTerminueberpruefung: false,
        fromOnlinebuchung: false,
    });
    const [checkedAppointmentData, setCheckedAppointmentData] = useState<ICheckedAppointmentData>({
        userId: '',
        t0: '',
        otp: '',
        reason: '',
    });
    const screenSize = useScreenResolution();
    const themeContext = useContext(ThemeContext);
    const { alertdispatch } = useContext(AlertContext);

    const brandingSrc = 'data:image/jpg;base64,' + brandingBytes;

    const scanInputRefUEB = useRef<HTMLInputElement>(null);
    const scanInputRefOBU = useRef<HTMLInputElement>(null);
    const scanInputRefTUE = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const bc = ScanUtils();
        if (bc) {
            bc.startup(document.getElementById('MainSplashscreen'), '', barcodeScannerCallback);
        }

        let ueb = false;
        let obu = false;
        let pan = false;
        let tue = false;

        if (hashParams.tileUEB) {
            ueb = hashParams.tileUEB;
        }

        if (hashParams.tileOBU) {
            obu = hashParams.tileOBU;
        }

        if (hashParams.tilePAN) {
            pan = hashParams.tilePAN;
        }

        if (hashParams.tileTUE) {
            tue = hashParams.tileTUE;
        }
        setVisibleTiles({
            tileUeberweisung: ueb,
            tileOnlinebuchung: obu,
            tilePraxisanmeldung: pan,
            tileTerminueberpruefung: tue,
            fromOnlinebuchung: false,
        });
        getProperties();

        return () => {
            if (bc) {
                bc.removeListeners(document.getElementById('MainSplashscreen'));
            }
        };
    }, []);

    const handleUrlTextAfterScan = async (scannedText: string) => {
        let urlToCall = '';

        let fromOBU = false;
        let fromUEB = false;
        let fromTUE = false;
        if (scannedText) {
            if (scanInputRefOBU.current && scanInputRefOBU.current.value.length > 0) {
                fromOBU = true;
            } else if (scanInputRefUEB.current && scanInputRefUEB.current.value.length > 0) {
                fromUEB = true;
            } else if (scanInputRefTUE.current && scanInputRefTUE.current.value.length > 0) {
                fromTUE = true;
            }

            // scannedText = scanInputRef.current?.value;
            const urlStart = scannedText.indexOf('https://');
            urlToCall = scannedText.substring(urlStart);

            const hashParamsResponse: any = getQueryParams(urlToCall);

            if (fromTUE) {
                const response = await checkAppointment(
                    hashParamsResponse.userId,
                    hashParamsResponse.otp,
                    hashParamsResponse.t0,
                    hashParamsResponse.reason,
                );

                if (response && response.state == 'error') {
                    showAlertMessage({
                        alertTitle: response.error.alertTitle,
                        alertTxt: response.error.alertTxt,
                        alertType: AlertType.error,
                        onHide: AlertOnHide.reload,
                        alertdispatch: alertdispatch,
                    });
                } else if (response && response.state == 'info') {
                    showAlertMessage({
                        alertTitle: response.info.alertTitle,
                        alertTxt: response.info.alertTxt,
                        alertType: AlertType.info,
                        onHide: AlertOnHide.onlyClose,
                        alertdispatch: alertdispatch,
                    });
                    setVisibleTiles({
                        ...visibleTiles,
                        tilePraxisanmeldung: true,
                        tileTerminueberpruefung: false,
                        fromOnlinebuchung: true,
                    });
                    setCheckedAppointmentData({
                        ...checkedAppointmentData,
                        userId: hashParamsResponse.userId,
                        t0: hashParamsResponse.t0,
                        otp: hashParamsResponse.otp,
                        reason: hashParamsResponse.reason,
                    });
                    /*
                    window.setTimeout(function () {
                        cancelClick();
                    }, 60000);
                    */
                }

                return;
            }

            if (hashParamsResponse.appId) {
                const newOTPData = await getOTPFromAppID(hashParamsResponse.appId, hashParamsResponse.secret);
                checkindispatch({
                    type: 'SETCHECKINDATA',
                    urlToCallResponse: {},
                    key: hashParamsResponse.key,
                    reason: 'ANY', // TODO: Irgendwann mal muessen wir das Ding los werden
                    requesttype: newOTPData.type,
                    wpName: hashParams.wpName,
                    userId: newOTPData.userId,
                    t0: newOTPData.t0,
                    otp: newOTPData.otp,
                });

                navigate(
                    Paths.QUESTIONNAIRE_WL +
                        '?reason=ANY' +
                        '&type=' +
                        newOTPData.type +
                        '&wpName=' +
                        hashParams.wpName,
                );
                setShowLoadingSpinner(false);
            } else {
                checkindispatch({
                    type: 'SETCHECKINDATA',
                    urlToCallResponse: {},
                    key: hashParamsResponse.key,
                    reason: hashParamsResponse.reason,
                    requesttype: hashParamsResponse.type,
                    wpName: hashParams.wpName,
                    userId: hashParamsResponse.userId,
                    t0: hashParamsResponse.t0,
                    otp: hashParamsResponse.otp,
                });

                navigate(
                    Paths.QUESTIONNAIRE_WL +
                        '?reason=' +
                        hashParamsResponse.reason +
                        '&type=' +
                        hashParamsResponse.type +
                        '&wpName=' +
                        hashParams.wpName,
                );
                setShowLoadingSpinner(false);
            }
        }
    };

    const barcodeScannerCallback: BarcodeScanCallback = async (txt) => {
        console.log(txt);
        if (txt.indexOf('https://') >= 0) {
            handleUrlTextAfterScan(txt);
        } else {
            if (txt.startsWith('06\t')) {
                const response = await interpreteKVParams(txt, hashParams.reason);
                if (response && response.url) {
                    // let urlToOpen = response.url;
                    // /* only for testing */
                    // urlToOpen =
                    //     'https://localhost:3000/#/NeuesProfilWL?type=NewAccount&key=https://demo.siegele-software.com&reason=ANMELDKARD&isMobile=false';
                    // window.open(urlToOpen, '_self');
                    const hashParamsResponse: INeuerAccountWL = getQueryParams(response.url);
                    checkindispatch({
                        type: 'SETCHECKINDATA',
                        urlToCallResponse: response,
                        key: hashParamsResponse.key,
                        reason: hashParamsResponse.reason,
                        requesttype: hashParamsResponse.type,
                        wpName: hashParams.wpName,
                    });

                    navigate(
                        Paths.NEUER_ACCOUNT_WL +
                            '?reason=' +
                            hashParamsResponse.reason +
                            '&type=' +
                            hashParamsResponse.type,
                    );
                    setShowLoadingSpinner(false);
                }
            }
        }
    };

    const getProperties = async () => {
        const propRes = await getAppProperties();
        setBrandingBytes(propRes.brandingBytes);
    };

    const lottieSpinnerOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingSplashWait,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const LottieSplashWait = () => {
        return <Lottie options={lottieSpinnerOptions} height={200} width={200} />;
    };

    const onHideAlertModal = () => {
        setShowLoadingSpinner(false);
        onclickMainsplacscreen();
    };

    const getURLToCallWorkflow = async () => {
        if (hashParams.wpName) {
            const response: IURLToCallResponse = await getURLToCall(
                hashParams.wpName,
                hashParams.reason,
                checkedAppointmentData.userId,
                checkedAppointmentData.t0,
                checkedAppointmentData.otp,
            );
            if (response && response.state === 'active') {
                // let urlToOpen = response.url;
                // /* only for testing */
                // urlToOpen =
                //     'https://localhost:3000/#/NeuesProfilWL?type=NewAccount&key=https://demo.siegele-software.com&reason=ANMELDKARD&isMobile=false';
                // window.open(urlToOpen, '_self');
                const hashParamsResponse: INeuerAccountWL = getQueryParams(response.url);
                checkindispatch({
                    type: 'SETCHECKINDATA',
                    urlToCallResponse: response,
                    key: hashParamsResponse.key,
                    reason: hashParamsResponse.reason,
                    requesttype: hashParamsResponse.type,
                    wpName: hashParams.wpName,
                });

                navigate(
                    Paths.NEUER_ACCOUNT_WL +
                        '?reason=' +
                        hashParamsResponse.reason +
                        '&type=' +
                        hashParamsResponse.type,
                );
                setShowLoadingSpinner(false);
            } else if (response && response.state == 'error') {
                if (response.error.alertTitle == 'Karte nicht gesteckt') {
                    showAlertMessage({
                        alertTitle: response.error.alertTitle,
                        alertTxt: response.error.alertTxt,
                        alertType: AlertType.error,
                        onHide: AlertOnHide.custom,
                        onHideCustom: onHideAlertModal,
                        alertdispatch: alertdispatch,
                    });
                } else {
                    showAlertMessage({
                        alertTitle: response.error.alertTitle,
                        alertTxt: response.error.alertTxt,
                        alertType: AlertType.error,
                        onHide: AlertOnHide.reload,
                        alertdispatch: alertdispatch,
                    });
                }
            } else if (response && response.state == 'info') {
                showAlertMessage({
                    alertTitle: response.info.alertTitle,
                    alertTxt: response.info.alertTxt,
                    alertType: AlertType.info,
                    onHide: AlertOnHide.reload,
                    alertdispatch: alertdispatch,
                });
            }
        }
    };

    const handleClickWithCard = () => {
        setShowLoadingSpinner(true);
        getURLToCallWorkflow();
    };

    const getArrowImageWidth = () => {
        if (screenSize.width > 1600) {
            return '50%';
        } else if (screenSize.width > 1000) {
            return '70%';
        } else {
            return '80%';
        }
    };

    const UeberweisungTile = () => {
        if (visibleTiles.tileUeberweisung) {
            return (
                <Col>
                    <CheckInCenterDiv>
                        <h1>Überweisung</h1>
                        <h3>Daten übertragen</h3>
                        {htmlBeforeOrAfter('UEBBEFORE', apstate.tileTexts.UEBBEFORE)}
                        <SpaceBetweenDiv
                            style={{
                                height: '100px',
                                width: screenSize.width > 1000 ? '50%' : '70%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                backgroundColor: 'white',
                                borderRadius: '100px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                boxShadow: themeContext.button.boxShadow,
                            }}
                        >
                            <VerticalCenterFlexDiv>
                                <BiBarcodeReader size="80px" />
                            </VerticalCenterFlexDiv>
                            <VerticalCenterFlexDiv fontSize="1.5rem" divWidth="100%">
                                <Form style={{ marginBlockEnd: '0em' }}>
                                    <Form.Group
                                        controlId="scanInputField"
                                        style={{ marginBottom: '0rem', marginBlockEnd: '0em' }}
                                    >
                                        <Form.Control
                                            ref={scanInputRefUEB}
                                            autoFocus
                                            style={{
                                                height: '70px',
                                                width: '100%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                borderRadius: '100px',
                                                borderColor: 'transparent',
                                                textAlign: 'center',
                                                fontSize: screenSize.width > 1300 ? '1.5rem' : '1rem',
                                            }}
                                            type="text"
                                            placeholder="Code scannen"
                                        />
                                    </Form.Group>
                                </Form>
                            </VerticalCenterFlexDiv>{' '}
                        </SpaceBetweenDiv>
                        {htmlBeforeOrAfter('UEBAFTER', apstate.tileTexts.UEBAFTER)}
                    </CheckInCenterDiv>
                </Col>
            );
        } else {
            return null;
        }
    };

    const OnlinebuchungTile = () => {
        if (visibleTiles.tileOnlinebuchung) {
            return (
                <Col>
                    <CheckInCenterDiv>
                        <h1>Onlinebuchung</h1>
                        <h3>Buchungsdatei einsehen / vervollständigen</h3>
                        {htmlBeforeOrAfter('OBUBEFORE', apstate.tileTexts.OBUBEFORE)}
                        <SpaceBetweenDiv
                            style={{
                                height: '100px',
                                width: screenSize.width > 1000 ? '50%' : '70%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                backgroundColor: 'white',
                                borderRadius: '100px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                boxShadow: themeContext.button.boxShadow,
                            }}
                        >
                            <VerticalCenterFlexDiv>
                                <BiBarcodeReader size="80px" />
                            </VerticalCenterFlexDiv>
                            <VerticalCenterFlexDiv fontSize="1.5rem" divWidth="100%">
                                <Form style={{ marginBlockEnd: '0em' }}>
                                    <Form.Group
                                        controlId="scanInputField"
                                        style={{ marginBottom: '0rem', marginBlockEnd: '0em' }}
                                    >
                                        <Form.Control
                                            ref={scanInputRefOBU}
                                            autoFocus
                                            style={{
                                                height: '70px',
                                                width: '100%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                borderRadius: '100px',
                                                borderColor: 'transparent',
                                                textAlign: 'center',
                                                fontSize: screenSize.width > 1300 ? '1.5rem' : '1rem',
                                            }}
                                            type="text"
                                            placeholder="Code scannen"
                                        />
                                    </Form.Group>
                                </Form>
                            </VerticalCenterFlexDiv>{' '}
                        </SpaceBetweenDiv>
                        {htmlBeforeOrAfter('OBUAFTER', apstate.tileTexts.OBUAFTER)}
                    </CheckInCenterDiv>
                </Col>
            );
        } else {
            return null;
        }
    };

    const cancelClick = () => {
        window.location.reload();
    };

    const PraxisanmeldungTile = () => {
        if (visibleTiles.tilePraxisanmeldung) {
            return (
                <Col>
                    <CheckInCenterDiv>
                        <h1>Praxisanmeldung</h1>
                        <h3>Gesundheitskarte einlesen</h3>
                        {htmlBeforeOrAfter('PANBEFORE', apstate.tileTexts.PANBEFORE)}
                        <ReadCardButton
                            width={screenSize.width > 1000 ? '50%' : '70%'}
                            textSize={screenSize.width > 1300 ? '1.5rem' : '1rem'}
                            disabled={showLoadingSpinner}
                            onClick={handleClickWithCard}
                        ></ReadCardButton>
                        {htmlBeforeOrAfter('PANAFTER', apstate.tileTexts.PANAFTER)}
                        {visibleTiles.fromOnlinebuchung && (
                            <RightDiv marginTop="20px" onClick={cancelClick} style={{ cursor: 'pointer' }}>
                                <IconDiv style={{ display: 'inline-block' }}>
                                    <MyTooltip
                                        id="LogoutTooltip"
                                        text="Zurück zum Startbildschirm"
                                        iconDiv={true}
                                        divWidth="50px"
                                        marginLeft="0"
                                        marginRight="5px"
                                        icon={
                                            <RiLogoutBoxRLine
                                                size={50}
                                                color={themeContext.icon.default.color}
                                                style={{ marginTop: 2 }}
                                            />
                                        }
                                    ></MyTooltip>
                                </IconDiv>
                                <span style={{ marginRight: '10px' }}>Startbildschirm</span>
                            </RightDiv>
                        )}
                    </CheckInCenterDiv>
                </Col>
            );
        } else {
            return null;
        }
    };

    const htmlBeforeOrAfter = (id: string, html: string) => {
        if (html) {
            return <div id="{id}" dangerouslySetInnerHTML={{ __html: html }} />;
        }
        return null;
    };
    const TerminueberpruefenTile = () => {
        if (visibleTiles.tileTerminueberpruefung) {
            return (
                <Col>
                    <CheckInCenterDiv>
                        <h1>Termin überprüfen</h1>
                        <h3>QR Code überprüfen</h3>
                        {htmlBeforeOrAfter('TUEBEFORE', apstate.tileTexts.TUEBEFORE)}
                        <SpaceBetweenDiv
                            style={{
                                height: '100px',
                                width: screenSize.width > 1000 ? '50%' : '70%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                backgroundColor: 'white',
                                borderRadius: '100px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                boxShadow: themeContext.button.boxShadow,
                            }}
                        >
                            <VerticalCenterFlexDiv>
                                <BiBarcodeReader size="80px" />
                            </VerticalCenterFlexDiv>
                            <VerticalCenterFlexDiv fontSize="1.5rem" divWidth="100%">
                                <Form style={{ marginBlockEnd: '0em' }}>
                                    <Form.Group
                                        controlId="scanInputField"
                                        style={{ marginBottom: '0rem', marginBlockEnd: '0em' }}
                                    >
                                        <Form.Control
                                            ref={scanInputRefTUE}
                                            autoFocus
                                            style={{
                                                height: '70px',
                                                width: '100%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                borderRadius: '100px',
                                                borderColor: 'transparent',
                                                textAlign: 'center',
                                                fontSize: screenSize.width > 1300 ? '1.5rem' : '1rem',
                                            }}
                                            type="text"
                                            placeholder="Code scannen"
                                        />
                                    </Form.Group>
                                </Form>
                            </VerticalCenterFlexDiv>{' '}
                        </SpaceBetweenDiv>
                        {htmlBeforeOrAfter('TUEAFTER', apstate.tileTexts.TUEAFTER)}
                    </CheckInCenterDiv>
                </Col>
            );
        } else {
            return null;
        }
    };

    const useV2 = true;

    const onclickMainsplacscreen = () => {
        const mainWindow = document.getElementById('MainSplashscreen');
        if (mainWindow) {
            const firstInp = mainWindow.querySelector('input');
            if (firstInp) {
                window.setTimeout(function () {
                    firstInp.focus();
                }, 0);
            } else {
                const firstButton = mainWindow.querySelector('button');
                if (firstButton) {
                    window.setTimeout(function () {
                        firstButton.focus();
                    }, 0);
                }
            }
        }
    };
    const getContent = () => {
        if (useV2) {
            return (
                <>
                    <div
                        style={{
                            height: '100vh',
                            width: '100vw',
                            /* background: '#54595f', */
                            overflow: 'hidden',
                            /* color: 'white' ,*/
                        }}
                        id="MainSplashscreen"
                        onClick={onclickMainsplacscreen}
                    >
                        <div style={{ textAlign: 'center' }}>
                            <Image src={brandingSrc} fluid style={{ paddingTop: 20 }} />
                        </div>
                        <CenterDiv>
                            <h1>Willkommen</h1>
                            <h3>beim Self Check-in</h3>{' '}
                        </CenterDiv>
                        <Row xs={1} sm={1} md={1} lg={2} style={{ justifyContent: 'center' }}>
                            <UeberweisungTile />
                            <OnlinebuchungTile />
                            <TerminueberpruefenTile />
                            <PraxisanmeldungTile />
                        </Row>
                    </div>
                    <CenterPositionFixedDiv>{showLoadingSpinner ? <LottieSplashWait /> : null}</CenterPositionFixedDiv>
                </>
            );
        } else {
            return null;
            // return (
            //     <>
            //         <div
            //             style={{ height: '100vh', width: '100vw', background: '#a3c1e9', overflow: 'hidden' }}
            //             id="MainSplashscreen"
            //         >
            //             <CenterDiv>
            //                 <CenterDiv marginBottom="100px" marginTop="20px">
            //                     <Row>
            //                         <Col></Col>
            //                         <Col>
            //                             <Image src={willkommenImage} width={'500px'} />
            //                         </Col>
            //                         <Col></Col>
            //                     </Row>
            //                     {/* {showLoadingSpinner ? <LottieSplashWait /> : null} */}
            //                     <Row>
            //                         <Col>
            //                             <Image src={terminVorhandenImage} width={getArrowImageWidth()} />
            //                         </Col>
            //                         <Col>
            //                             <Image src={keinTerminImage} width={getArrowImageWidth()} />
            //                         </Col>
            //                     </Row>
            //                 </CenterDiv>
            //                 <CenterRow>
            //                     <Col>
            //                         <SpaceBetweenDiv
            //                             style={{
            //                                 height: '100px',
            //                                 width: screenSize.width > 1000 ? '50%' : '70%',
            //                                 marginLeft: 'auto',
            //                                 marginRight: 'auto',
            //                                 backgroundColor: 'white',
            //                                 borderRadius: '100px',
            //                                 paddingLeft: '20px',
            //                                 paddingRight: '20px',
            //                                 boxShadow: themeContext.button.boxShadow,
            //                             }}
            //                         >
            //                             <VerticalCenterFlexDiv>
            //                                 <BiBarcodeReader size="80px" />
            //                             </VerticalCenterFlexDiv>
            //                             <VerticalCenterFlexDiv fontSize="1.5rem" divWidth="100%">
            //                                 <Form style={{ marginBlockEnd: '0em' }}>
            //                                     <Form.Group
            //                                         controlId="scanInputField"
            //                                         style={{ marginBottom: '0rem', marginBlockEnd: '0em' }}
            //                                     >
            //                                         <Form.Control
            //                                             ref={scanInputRef}
            //                                             autoFocus
            //                                             style={{
            //                                                 height: '70px',
            //                                                 width: '100%',
            //                                                 marginLeft: 'auto',
            //                                                 marginRight: 'auto',
            //                                                 borderRadius: '100px',
            //                                                 borderColor: 'transparent',
            //                                                 textAlign: 'center',
            //                                                 fontSize: screenSize.width > 1300 ? '1.5rem' : '1rem',
            //                                             }}
            //                                             type="text"
            //                                             placeholder="Code scannen"
            //                                         />
            //                                     </Form.Group>
            //                                 </Form>
            //                             </VerticalCenterFlexDiv>
            //                         </SpaceBetweenDiv>
            //                     </Col>
            //                     <Col>
            //                         <ReadCardButton
            //                             width={screenSize.width > 1000 ? '50%' : '70%'}
            //                             textSize={screenSize.width > 1300 ? '1.5rem' : '1rem'}
            //                             disabled={showLoadingSpinner}
            //                             onClick={handleClickWithCard}
            //                         ></ReadCardButton>
            //                     </Col>
            //                 </CenterRow>
            //             </CenterDiv>
            //         </div>
            //         <CenterPositionFixedDiv>{showLoadingSpinner ? <LottieSplashWait /> : null}</CenterPositionFixedDiv>
            //     </>
            // );
        }
    };

    return getContent();
};

export default CheckInSplashscreen;
