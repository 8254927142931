import * as React from 'react';
import styled from 'styled-components';
import useScreenResolution from '../../hooks/useScreenResolution';
import { ParagraphFontSize } from '../StyledParagraph/StyledParagraph';

interface IDiv {
    divWidth?: string;
    borderRight?: string;
    divTop?: string;
    margin?: string;
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    paddingTop?: string;
    paddingBottom?: string;
    paddingLeft?: string;
    paddingRight?: string;
    height?: string;
    textAlign?: string;
    color?: string;
    overflowX?: string;
    overflowY?: string;
    backgroundColor?: string;
    zIndex?: number;
    position?: string;
    bottom?: string;
    top?: string;
    left?: string;
    right?: string;
    maxHeight?: string;
    borderRadius?: string;
    lineHeight?: string;
    fontSize?: ParagraphFontSize | string;
}

interface IDivSplit {
    height?: string;
    width?: string;
    paddingTop?: string;
    backgroundColor?: string;
}

export const LeftDiv = styled.div<IDiv>`
    text-align: left;
    margin-top: ${(prop) => (prop.marginTop ? prop.marginTop : '0px')};
    margin-bottom: ${(prop) => (prop.marginBottom ? prop.marginBottom : '0px')};
    margin-left: ${(prop) => (prop.marginLeft ? prop.marginLeft : '0px')};
    margin-right: ${(prop) => (prop.marginRight ? prop.marginRight : '0px')};
`;

export const LeftDivMain = styled.div<IDiv>`
    text-align: left;
    margin-top: ${(prop) => (prop.marginTop ? prop.marginTop : '0px')};
    margin-bottom: ${(prop) => (prop.marginBottom ? prop.marginBottom : '0px')};
    margin-right: ${(prop) => (prop.marginRight ? prop.marginRight : '0px')};
    margin-left: 100px;
    @media only screen and (max-width: 800px) {
        margin-left: 60px;
    }
    @media only screen and (max-width: 500px) {
        margin-left: 0px;
    }
`;
export const AnmeldBaseDiv = styled.div<IDiv>`
    text-align: left;
    margin-left: ${(prop) => (prop.marginLeft ? prop.marginLeft : '50px')};
    margin-right: ${(prop) => (prop.marginRight ? prop.marginRight : '50px')};
    @media only screen and (max-width: 800px) {
        margin-left: 25px;
        margin-right: 25px;
    }
    @media only screen and (max-width: 500px) {
        margin-left: 0px;
        margin-right: 0px;
    }
`;

export const AnmeldBaseHeader = styled.div<IDiv>`
    position: sticky;
    z-index: 30;
    background-color: white;
    top: 190px;
    @media only screen and (max-width: 800px) {
        top: 170px;
    }
    @media only screen and (max-height: 700px) {
        top: 140px;
    }
    @media only screen and (max-height: 500px) {
        top: 60px;
    }
`;

export const AnmeldBaseHeaderLogin = styled.div<IDiv>`
    position: sticky;
    z-index: 30;
    background-color: white;
    top: 120px;
    @media only screen and (max-width: 800px) {
        top: 150px;
    }
    @media only screen and (max-height: 500px) {
        top: 200px;
    }
`;

export const LeftDivCheckIn = styled.div<IDiv>`
    text-align: left;
    margin-top: ${(prop) => (prop.marginTop ? prop.marginTop : '0px')};
    margin-bottom: ${(prop) => (prop.marginBottom ? prop.marginBottom : '0px')};
    margin-left: 100px;
    margin-right: 100px;
    @media only screen and (max-width: 800px) {
        margin-left: 25px;
        margin-right: 25px;
    }
    @media only screen and (max-width: 500px) {
        margin-left: 0px;
        margin-right: 0px;
    }
`;

export const RightDiv = styled.div<IDiv>`
    text-align: right;
    margin-top: ${(prop) => (prop.marginTop ? prop.marginTop : '0px')};
    margin-bottom: ${(prop) => (prop.marginBottom ? prop.marginBottom : '0px')};
    margin-left: ${(prop) => (prop.marginLeft ? prop.marginLeft : '0px')};
    margin-right: ${(prop) => (prop.marginRight ? prop.marginRight : '0px')};
`;

export const CenterDiv = styled.div<IDiv>`
    text-align: center;
    height: ${(prop) => (prop.height ? prop.height : null)};
    margin: ${(prop) => (prop.margin ? prop.margin : null)};
    margin-top: ${(prop) => (prop.marginTop ? prop.marginTop : '0px')};
    margin-bottom: ${(prop) => (prop.marginBottom ? prop.marginBottom : '0px')};
    margin-left: ${(prop) => (prop.marginLeft ? prop.marginLeft : '0px')};
    margin-right: ${(prop) => (prop.marginRight ? prop.marginRight : '0px')};
    padding-top: ${(prop) => (prop.paddingTop ? prop.paddingTop : null)};
    padding-bottom: ${(prop) => (prop.paddingBottom ? prop.paddingBottom : null)};
    padding-left: ${(prop) => (prop.paddingLeft ? prop.paddingLeft : null)};
    padding-right: ${(prop) => (prop.paddingRight ? prop.paddingRight : null)};
    overflow-x: ${(prop) => (prop.overflowX ? prop.overflowX : null)};
    overflow-y: ${(prop) => (prop.overflowY ? prop.overflowY : null)};
    color: ${(prop) => (prop.color ? prop.color : 'black')};
    background-color: ${(prop) => (prop.backgroundColor ? prop.backgroundColor : null)};
    z-index: ${(prop) => (prop.zIndex ? prop.zIndex : null)};
    position: ${(prop) => (prop.position ? prop.position : null)};
    bottom: ${(prop) => (prop.bottom ? prop.bottom : null)};
    top: ${(prop) => (prop.top ? prop.top : null)};
    right: ${(prop) => (prop.right ? prop.right : null)};
    left: ${(prop) => (prop.left ? prop.left : null)};
    max-height: ${(prop) => (prop.maxHeight ? prop.maxHeight : null)};
    font-size: ${(prop) => (prop.fontSize ? prop.fontSize : null)};
`;

export const CheckInCenterDiv = styled.div<IDiv>`
    text-align: center;
    height: ${(prop) => (prop.height ? prop.height : null)};
    margin: ${(prop) => (prop.margin ? prop.margin : null)};
    margin-top: ${(prop) => (prop.marginTop ? prop.marginTop : '10px')};
    margin-bottom: ${(prop) => (prop.marginBottom ? prop.marginBottom : '10px')};
    margin-left: ${(prop) => (prop.marginLeft ? prop.marginLeft : '30px')};
    margin-right: ${(prop) => (prop.marginRight ? prop.marginRight : '30px')};
    padding-top: ${(prop) => (prop.paddingTop ? prop.paddingTop : '10px')};
    padding-bottom: ${(prop) => (prop.paddingBottom ? prop.paddingBottom : '10px')};
    padding-left: ${(prop) => (prop.paddingLeft ? prop.paddingLeft : null)};
    padding-right: ${(prop) => (prop.paddingRight ? prop.paddingRight : null)};
    overflow-x: ${(prop) => (prop.overflowX ? prop.overflowX : null)};
    overflow-y: ${(prop) => (prop.overflowY ? prop.overflowY : null)};
    color: ${(prop) => (prop.color ? prop.color : 'black')};
    background-color: ${(prop) => (prop.backgroundColor ? prop.backgroundColor : 'lightgray')};
    z-index: ${(prop) => (prop.zIndex ? prop.zIndex : null)};
    position: ${(prop) => (prop.position ? prop.position : null)};
    bottom: ${(prop) => (prop.bottom ? prop.bottom : null)};
    top: ${(prop) => (prop.top ? prop.top : null)};
    right: ${(prop) => (prop.right ? prop.right : null)};
    left: ${(prop) => (prop.left ? prop.left : null)};
    max-height: ${(prop) => (prop.maxHeight ? prop.maxHeight : null)};
    font-size: ${(prop) => (prop.fontSize ? prop.fontSize : null)};
`;

export const StickyHeaderTitleDiv = styled.div<IDiv>`
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 20;
    height: 57px;
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : props.theme.header.main.backgroundColor};
    padding-top: ${(prop) => (prop.paddingTop ? prop.paddingTop : '0px')};
`;

export const StickyStepperWithNavbar = styled.div<IDiv>`
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 30;
    height: 190px;
    @media only screen and (max-width: 800px) {
        height: 170px;
    }
    @media only screen and (max-height: 700px) {
        height: 140px;
    }
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'white')};
    padding-top: ${(prop) => (prop.paddingTop ? prop.paddingTop : '0px')};
`;

export const StickyStepperDiv = styled.div<IDiv>`
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 30;
    height: 120px;
    @media only screen and (max-width: 800px) {
        height: 150px;
    }
    @media only screen and (max-height: 500px) {
        height: 200px;
    }
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'white')};
    padding-top: ${(prop) => (prop.paddingTop ? prop.paddingTop : '0px')};
`;

export const StickyButtonRowBottomDiv = styled.div<IDiv>`
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    z-index: 20;
    height: 100px;
    padding-top: ${(prop) => (prop.paddingTop ? prop.paddingTop : '0px')};
    margin-top: ${(prop) => (prop.marginTop ? prop.marginTop : '10px')};
    background-color: white;
`;

export const StickyStepperRowDiv = styled.div<IDiv>`
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 20;
    height: 57px;
    background-color: white;
    padding-top: 0px;
    margin-bottom: 70px;
`;

export const StickyHeaderTabsDiv = styled.div<IDiv>`
    position: -webkit-sticky;
    position: sticky;
    top: 55px;
    z-index: 30;
    /* height: 65px; */
    padding-top: 20px;
    background-color: ${(props) => props.theme.mainColor};
    background-image: ${(props) => props.theme.tabcontainer.backgroundImage};
    height: ${(prop) => (prop.height ? prop.height : 'auto')};
    border-radius: 10px;
`;

export const StickyTagesplanSectionDiv = styled.div<IDiv>`
    position: -webkit-sticky;
    position: sticky;
    top: 55px;
    z-index: 20;
    height: 57px;
    background-color: ${(props) => props.theme.mainColor};
    background-image: ${(props) => props.theme.tabcontainer.backgroundImage};
    border-radius: 10px;
    padding-top: ${(prop) => (prop.paddingTop ? prop.paddingTop : '0px')};
`;

export const VerticalCenterDiv = styled.div<IDiv>`
    width: 100%;
    position: absolute;
    top: ${(prop) => (prop.divTop ? prop.divTop : '50%')};
    left: 50%;
    transform: translate(-50%, -50%);
    border-right: ${(prop) => (prop.borderRight ? prop.borderRight : 'none')};
`;

export const VerticalCenterFlexDiv = styled.div<IDiv>`
    display: flex;
    align-items: center;
    font-size: ${(prop) => (prop.fontSize ? prop.fontSize : null)};
    width: ${(prop) => (prop.divWidth ? prop.divWidth : null)};
`;

export const IconDiv = styled.div<IDiv>`
    width: ${(prop) => (prop.divWidth ? prop.divWidth : 'auto')};
    margin-left: ${(prop) => (prop.marginLeft ? prop.marginLeft : '0px')};
    margin-right: ${(prop) => (prop.marginRight ? prop.marginRight : '0px')};
    &:hover {
        background-color: ${(props) => props.theme.icon.default.backgroundColorHover};
        border-radius: ${(props) => (props.borderRadius ? props.borderRadius : props.theme.borderRadius)};
    }
`;

export const MainHeaderButtonIcon = styled.div<IDiv>`
    width: ${(prop) => (prop.divWidth ? prop.divWidth : 'auto')};
    margin-left: 20px;
    margin-right: 20px;
    &:hover {
        background-color: ${(props) => props.theme.icon.default.backgroundColorHover};
        border-radius: ${(props) => (props.borderRadius ? props.borderRadius : props.theme.borderRadius)};
    }
    padding: 3px;
`;

export const BreakFlexDiv = styled.div<IDiv>`
    flex-basis: 100%;
    height: 0;
`;

export const ChartInnerDiv = styled.div<IDiv>`
    position: absolute;
    width: 110%;
    padding-top: 5px;
    overflow: hidden;
    margin-right: -100px;
`;

export const ChartOuterDiv = styled.div<IDiv>`
    position: relative;
    height: 500px;
    width: 95%;
    overflow: hidden;
`;

export const NewMessageBadgeDiv = styled.div`
    position: absolute;
    top: 45px;
    background-color: ${(props) => props.theme.selectButton.backgroundColorActive};
    width: 20px;
    height: 20px;
    padding: 2px;
    border-radius: 50%;
    text-align: center;
    font-size: 0.8rem;
    font-weight: bold;
`;

export const MailParentDiv = styled.div<IDiv>`
    height: ${(prop) => (prop.height ? prop.height : '700px')};
    margin-top: 10px;
    overflow: hidden;
`;

export const MailListDiv = styled.div<IDiv>`
    position: relative;
    height: ${(prop) => (prop.height ? prop.height : '50%')};
    margin-top: 10px;
    margin-bottom: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
`;

export const MailContentDiv = styled.div<IDiv>`
    position: -webkit-sticky;
    position: sticky;
    z-index: 20;
    height: 53%;
    border-style: solid;
    border-width: thin;
    overflow-y: scroll;
    overflow-x: hidden;
`;

export const MailContentHeaderDiv = styled.div<IDiv>`
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 20;
    text-align: left;
    background-color: white;
`;

export const SplitLeftDiv = styled.div<IDivSplit>`
    height: ${(prop) => (prop.height ? prop.height : '100%')};
    width: ${(prop) => (prop.width ? prop.width : '50%')};
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: ${(prop) => (prop.paddingTop ? prop.paddingTop : '20px')};
    left: 0;
    background-color: ${(prop) => (prop.backgroundColor ? prop.backgroundColor : 'white')};
`;

export const SplitRightDiv = styled.div<IDivSplit>`
    height: ${(prop) => (prop.height ? prop.height : '100%')};
    width: ${(prop) => (prop.width ? prop.width : '50%')};
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: ${(prop) => (prop.paddingTop ? prop.paddingTop : '0px')};
    right: 0;
    background-color: ${(prop) => (prop.backgroundColor ? prop.backgroundColor : 'white')};
`;

export const StyledStepperContentDiv = styled.div<IDiv>`
    margin-left: ${(prop) => (prop.marginLeft ? prop.marginLeft : '120px')};
    margin-right: ${(prop) => (prop.marginRight ? prop.marginRight : '120px')};
`;

export const StepperContentDiv = ({ children }: React.PropsWithChildren<unknown>) => {
    const screenSize = useScreenResolution();
    let marginLeft = '200px';
    let marginRight = '200px';

    if (screenSize.width < 1430) {
        marginLeft = '30px';
        marginRight = '30px';
    }
    return (
        <StyledStepperContentDiv marginLeft={marginLeft} marginRight={marginRight}>
            {children}
        </StyledStepperContentDiv>
    );
};

export const EncounterDiv = styled.div<IDiv>`
    text-align: left;
    font-weight: bold;
    padding-top: 6px;
    margin-right: 10px;
`;

export const EmbeddedPDFContainer = styled.div<IDiv>`
    /*
    position: fixed;
    top: 80px;
*/
    position: relative;
    margin-top: 5px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 20;
    height: 90%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: ${(prop) => (prop.overflowX ? prop.overflowX : 'hidden')};
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const EmbeddedPDFFooter = styled.div<IDiv>`
    /* width: 400px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 5px;
    padding: 5px;
    left: 50%;
    background: white;
    opacity: 95%;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px; */
    position: sticky;
    bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    z-index: 100;
`;

export const EmbeddedPDFHeader = styled.div<IDiv>`
    position: -webkit-sticky;
    position: sticky;
    top: 30px;
    z-index: 50;
`;

export const EmbeddedPDFContent = styled.div<IDiv>`
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
`;

export const EmbeddedSignDocumentModalContainer = styled.div<IDiv>``;

export const SpaceBetweenDiv = styled.div<IDiv>`
    display: flex;
    justify-content: space-between;
    flex-direction: 'row';
    line-height: ${(prop) => (prop.lineHeight ? prop.lineHeight : null)};
    margin-top: ${(prop) => (prop.marginTop ? prop.marginTop : null)};
    margin-bottom: ${(prop) => (prop.marginBottom ? prop.marginBottom : null)};
    margin-left: ${(prop) => (prop.marginLeft ? prop.marginLeft : null)};
    margin-right: ${(prop) => (prop.marginRight ? prop.marginRight : null)};
`;

export const ImpressumBottom = styled.div<IDiv>`
    position: fixed;
    background-color: white;
    bottom: 0;
    width: 100%;
    z-index: 50;
    height: 2em;
    text-align: center;
    font-size: ${(prop) => (prop.fontSize ? prop.fontSize : null)};
`;

export const ContentWithoutImpressum = styled.div<IDiv>`
    margin-bottom: 2em;
    height: calc(100vh - 2em);
    overflow: auto;
    box-sizing: border-box;
`;

export const CenterPositionFixedDiv = styled.div<IDiv>`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
