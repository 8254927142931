import { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { Paths } from '../../Routes';
import AppointmentCalendarWithHeadline from '../../components/AppointmentCalendar/AppointmentCalendarWithHeadline';
import AppointmentPersonalDataWithCardRead from '../../components/AppointmentPersonalData/AppointmentPersonalDataWithCardRead';
import AppointmentPersonalDataWithLogin from '../../components/AppointmentPersonalData/AppointmentPersonalDataWithLogin';
import BookingAppointment from '../../components/Booking/BookingAppointment';
import { IIncomingMessage } from '../../components/Chat/Chat';
import { CenterDiv, LeftDivCheckIn, StickyStepperDiv, StickyStepperWithNavbar } from '../../components/Div/StyledDiv';
import FinalCheckIn from '../../components/FinalCheckIn/FinalCheckIn';
import { IFrameInternal } from '../../components/IFrame/IFrame';
import IFramePDF from '../../components/IFrame/IFramePDF';
import LoadingSpinnerWithText from '../../components/Loading/LoadingSpinnerWithText';
import LoginCardModal, { ILoginCardModalShow } from '../../components/Modals/LoginCardModal/LoginCardModal';
import PDFModal from '../../components/Modals/PDFModal/PDFModal';
import TelephoneModal from '../../components/Modals/TelephoneModal/TelephoneModal';
import StepperCheckIn, {
    IStepperCheckInStep,
    StepTyp,
    StepperLabelOrientation,
    StepperOrientation,
} from '../../components/Stepper/StepperCheckIn';
import { ITelephoneCallIcon, stopRingTone } from '../../components/Telephone/Telephone';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import AppPropsContext from '../../context/appPropsContext';
import CheckInContext from '../../context/checkInContext';
import LoginContext, { IActivePatients, ICodeName } from '../../context/loginContext';
import { IQAnswers, IQData } from '../../context/questionnaireContext';
import TerminierungContext from '../../context/terminierungContext';
import VideochatContext from '../../context/videochatContext';
import { PROFILE_HOME, securityItemNames } from '../../globals/global';
import { useAppProperties } from '../../hooks/useAppProperties';
import { useAppointmentSelection } from '../../hooks/useAppointmentSelection';
import { useAuth } from '../../hooks/useAuth';
import { useHandleLogoutCheckIn } from '../../hooks/useHandleLogout';
import { useLoadQuestionnaireAnswers } from '../../hooks/useLoadQuestionnaireAnswers';
import { useMessages } from '../../hooks/useMessages';
import { usePatientSwitch } from '../../hooks/usePatientSwitch';
import { usePersonalData } from '../../hooks/usePersonalData';
import useScreenResolution from '../../hooks/useScreenResolution';
import {
    IAuthorizeAsync,
    IEnrollData,
    IQuestionnaireJourneyData,
    authorizeAsync,
    getEnrollStatus,
    getQuestionnaireJourney,
    unblockAppointment,
    updateEnroll,
} from '../../services/RestServices';
import { initWebSocket } from '../../services/VideochatService';
import { getDiagnosticReport } from '../../services/fhir/FHIRDiagnosticReports';
import { getQuestionnaireContent, getQuestionnaireOTPAuth } from '../../services/fhir/FHIRQuestionnaire';
import { showAlertMessage } from '../../utils/alertHandling';
import { getPDFDataFromDiagnosticReportResponse } from '../../utils/documentUtils';
import { getFullOTPAuthorization } from '../../utils/pcpUtils';
import { getQuestionnaireResourceFromRequest } from '../../utils/questionnaireUtils';
import AppointmentCategoryDepartment from '../AppointmentCategory/AppointmentCategoryDepartment';
import AppointmentCategoryExtendedPhysician from '../AppointmentCategory/AppointmentCategoryExtendedPhysican';
import AppointmentCategoryLocation from '../AppointmentCategory/AppointmentCategoryLocation';
import AppointmentCategoryPhysician from '../AppointmentCategory/AppointmentCategoryPhysician';
import AppointmentCategoryReason from '../AppointmentCategory/AppointmentCategoryReason';
import { IPDFModalShow } from '../Dokumente/Dokumente';
import FragebogenDetail from '../Fragebogen/FragebogenDetail';
import { IQuestionnaireStepData, IQuestionnaireWL } from '../Fragebogen/QuestionnaireWL';
import FullScreenImpressum from '../Mainscreen/FullScreenImpressum';
import NavbarCheckInWL from '../Navbar/NavbarCheckInWL';
import NeuerAccount from '../NeuerAccount/NeuerAccount';
import { INeuerAccountWL } from '../NeuerAccount/NeuerAccountWL';
import PersonalDataSummary from '../PersonalDataSummary/PersonalDataSummary';
import TermineCheckIn from '../Termine/TermineCheckIn';
import Upload from '../Upload/Upload';
import VideochatWL from '../Videochat/VideochatWL';
// import {s} from '../../services/SignaturePad/signotec/STPadServerLib-3.2.0'

export interface IRegisteredUserParams {
    reason: string;
    userId: string;
    t0: string;
    otp: string;
    isCheckin?: boolean;
}

export interface ICheckInWL {
    newAccountHashParams?: INeuerAccountWL;
    registeredUserHashParams?: IRegisteredUserParams;
    journeyResponse?: IQuestionnaireJourneyData;
    useLoginContext: boolean;
    isPhysicianLogin?: boolean;
}

export interface IAuthData {
    finalURL?: string;
    otp: string;
    t0: string;
    userId: number;
}

interface IReloadPageData {
    reload: boolean;
    withJourneyReload: boolean;
    nextActiveStep: number;
    newStepValues?: IChangeStep[];
}

interface IChangeStep {
    id: string;
    completed: boolean;
    draft: boolean;
    active: boolean;
    clickable: boolean;
}

const CheckInWL = (props: ICheckInWL) => {
    const navigate = useNavigate();
    const location = useLocation();
    const screenSize = useScreenResolution();
    // const location = useLocation();
    // const hashParams: ICheckInWL = getQueryParams(location.search);

    const { state, dispatch } = useContext(LoginContext);
    const { tmstate, tmdispatch } = useContext(TerminierungContext);
    const { alertdispatch } = useContext(AlertContext);
    const { getAndSetPersonalData, getEnrollData } = usePersonalData();
    const { apstate, apdispatch } = useContext(AppPropsContext);
    const { vsstate, vsdispatch } = useContext(VideochatContext);
    const { checkinstate } = useContext(CheckInContext);
    const themeContext = useContext(ThemeContext);

    const { handleLogoutCheckIn } = useHandleLogoutCheckIn();

    const { allowMessages, forbidMessages, videochatMessagesAllowed } = useMessages();

    const [
        setSelectedQuestionnaire,
        setQuestionnaireBody,
        loadQuestionnaireAnswers,
        setSelectedQuestionnaireAnswers,
        setQuestionnaireData,
    ] = useLoadQuestionnaireAnswers();

    const { setCheckInAuth, hasAnmeldContexts } = useAuth();

    const { setNewPatient } = usePatientSwitch();

    const { getImpressum, isExtendedPhysicians, enableFullscreenMode, disableFullscreenMode, isFullscreenMode } =
        useAppProperties();

    const { getSelectedResource } = useAppointmentSelection();

    const [allSteps, setAllSteps] = useState<IStepperCheckInStep[]>([]);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [stepClicked, setStepClicked] = useState(false);
    const [activeContent, setActiveContent] = useState<JSX.Element>(<></>);
    const [authData, setAuthData] = useState<IAuthData>({ finalURL: '', otp: '', t0: '', userId: 0 });
    const [journeyData, setJourneyData] = useState<IQuestionnaireJourneyData>();
    const [pdfModalShow, setPdfModalShow] = useState<IPDFModalShow>({
        show: false,
        modalTitle: '',
        pdfData: '',
        isSignable: false,
        diagnosticReportData: '',
    });

    const [loginModalShow, setLoginModalShow] = useState<ILoginCardModalShow>({
        show: false,
    });
    const [questionnaireLoaded, setQuestionnaireLoaded] = useState(false);
    const [showTelephoneModal, setShowTelephoneModal] = useState(false);
    const [clickedQuestSave, setClickedQuestSave] = useState(false);
    const [reloadContent, setReloadContent] = useState(false);
    const [updateEnrollmentRequest, setUpdateEnrollmentRequest] = useState(false);

    const [videosprechstundeStepLoad, setVideosprechstundeStepLoad] = useState(false);
    const [videochatHangup, setVideochatHangup] = useState(false);

    const [reloadPageData, setReloadPageData] = useState<IReloadPageData>({
        reload: false,
        withJourneyReload: false,
        nextActiveStep: 0,
        newStepValues: [
            {
                id: '',
                active: false,
                clickable: false,
                completed: false,
                draft: false,
            },
        ],
    });

    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

    const [cardReadClickedInPersData, setCardReadClickedInPersData] = useState(false);
    const [initPageDone, setInitPageDone] = useState(false);

    const [forceClosePDF, setForceClosePDF] = useState(false);

    const [registerdUserData, setRegisteredUserData] = useState<IRegisteredUserParams>({
        reason: '',
        userId: '',
        t0: '',
        otp: '',
        isCheckin: false,
    });

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const callProps: ITelephoneCallIcon = {
        state: state,
        vsstate: vsstate,
        vsdispatch: vsdispatch,
        tmstate: tmstate,
    };
    const conState: any = {};

    useEffect(() => {
        if (
            !props.useLoginContext &&
            props.registeredUserHashParams !== undefined &&
            props.registeredUserHashParams.otp.length > 0
        ) {
            setRegisteredUserData({
                reason: props.registeredUserHashParams.reason,
                userId: props.registeredUserHashParams.userId,
                t0: props.registeredUserHashParams.t0,
                otp: props.registeredUserHashParams.otp,
                isCheckin: props.registeredUserHashParams.isCheckin,
            });
        }
        const listener = () => {
            const resource_1 = getSelectedResource(1);
            if (resource_1) {
                unblockAppointment(resource_1);
            }
            return true;
        };
        window.addEventListener('beforeunload', listener);
        return () => window.removeEventListener('beforeunload', listener);
    }, []);

    useEffect(() => {
        if (registerdUserData !== undefined && registerdUserData.otp.length > 0) {
            const authData: IAuthData = {
                otp: registerdUserData.otp,
                t0: registerdUserData.t0.toString(),
                userId: Number(registerdUserData.userId),
            };

            setActiveStep(0);
            setAuthData(authData);
            getAndSetPersData(authData);
            getJourneyData();
        }
        getInitialSteps();
        getContent();

        if (!props.useLoginContext) {
            getProperties();
        }
    }, [registerdUserData]);

    useEffect(() => {
        if (clickedQuestSave) {
            getInitialSteps();
        } else {
            if (!props.useLoginContext) {
                setCheckInAuth(authData);
                newSetOfStepsAndContent();
            }
        }
        setClickedQuestSave(false);
    }, [authData, journeyData]);

    const mySetJourneyRessponse = (questionnaireJourneyResponse) => {
        setJourneyData(questionnaireJourneyResponse);
        if (questionnaireJourneyResponse.enrollCode) {
            tmdispatch({
                type: 'SETENROLLCODE',
                enrollData: {
                    enrollCode: questionnaireJourneyResponse.enrollCode,
                    kvBarcode: questionnaireJourneyResponse.kvBarcode,
                    isToday: questionnaireJourneyResponse.isToday,
                },
            });
        }
    };

    useEffect(() => {
        const reloadQuests = async () => {
            if (registerdUserData?.reason) {
                const auth = getFullOTPAuthorization('PCP', authData.otp, authData.userId, authData.t0);
                const questionnaireJourneyResponse = await getQuestionnaireJourney(
                    registerdUserData?.reason,
                    auth,
                    checkinstate.wpName,
                );
                if (questionnaireJourneyResponse) {
                    mySetJourneyRessponse(questionnaireJourneyResponse);
                }
            }
        };

        if (clickedQuestSave) {
            reloadQuests();
        }
    }, [clickedQuestSave]);

    useEffect(() => {
        // qdispatch({
        //     type: 'RESETQUESTIONNAIRE',
        // });

        if (stepClicked || reloadContent) {
            const content = getContent();
            if (content) {
                setActiveContent(content);
            }
            setStepClicked(false);
            setReloadContent(false);
        }
    }, [stepClicked]);

    useEffect(() => {
        const reloadChatMessages = async () => {
            if (tmstate.enrollData.enrollCode) {
                const responseEnroll = await getEnrollStatus(tmstate.enrollData.enrollCode);
                if (responseEnroll.error && !vsstate.jitsiError) {
                    vsdispatch({
                        type: 'JITSIENROLLERROR',
                        jitsiError:
                            'Der Termin wurde abgesagt oder storniert. Bitte vereinbaren Sie einen neuen Termin.',
                    });
                } else {
                    const allMsg: IIncomingMessage[] = responseEnroll.chat;
                    vsdispatch({
                        type: 'SETCHATMESSAGES',
                        chatMessages: allMsg,
                    });

                    if (!vsstate.jitsiAllowed) {
                        if (responseEnroll.jitsiAllowed) {
                            vsdispatch({
                                type: 'SETJITSIALLOWED',
                                jitsiAllowed: true,
                                roomName: responseEnroll.roomName,
                            });
                        }
                    }
                }
            }
        };

        if (vsstate.chatMessages && vsstate.chatMessages.length <= 0) {
            if (
                true ||
                (allSteps && activeStep && allSteps.length > activeStep && allSteps[activeStep].id != ID_VIDEO)
            ) {
                reloadChatMessages();
            }
        }

        if (!window.app.wsconnect) {
            if (tmstate.enrollData.enrollCode !== undefined && tmstate.enrollData.enrollCode.length > 0) {
                initWebSocket(
                    vsstate.iceServer,
                    tmstate.enrollData.enrollCode,
                    vsstate,
                    vsdispatch,
                    tmstate,
                    navigate,
                    location,
                );
            }
        }

        if (vsstate.incomingCall) {
            if (!videochatMessagesAllowed()) {
                return;
            }
            if (!showTelephoneModal) {
                if (allSteps[activeStep].id != ID_VIDEO) {
                    setShowTelephoneModal(true);
                } else {
                    if (!vsstate.jitsiAllowed) {
                        vsdispatch({
                            type: 'SETJITSIALLOWED',
                            jitsiAllowed: true,
                        });
                    }
                }
            }
        }

        if (vsstate.callAccepted) {
            const foundIndex = allSteps.findIndex((step) => {
                if (step.id == ID_VIDEO) {
                    return true;
                }
            });
            const reloadData: IReloadPageData = {
                reload: true,
                withJourneyReload: false,
                nextActiveStep: foundIndex,
                newStepValues: [
                    {
                        id: ID_VIDEO,
                        clickable: true,
                        active: true,
                        completed: false,
                        draft: false,
                    },
                ],
            };
            enableFullscreenMode();
            setReloadPageData(reloadData);
        }
    }, [tmstate, vsstate]);

    useEffect(() => {
        if (questionnaireLoaded) {
            const stepData: IQuestionnaireStepData = {
                activeStepNumber: activeStep,
                handleQuestionnaireFinished: handleQuestFinished,
                handleQuestionnairePDFClose: handleQuestPDFClose,
                handleQuestionnaireSave: handleQuestSave,
            };

            const auth = getFullOTPAuthorization('', authData.otp, authData.userId, authData.t0);
            setActiveContent(
                <FragebogenDetail
                    dataLoaded={true}
                    type="Questionnaire"
                    otp={auth}
                    t0={authData.t0}
                    userId={authData.userId.toString()}
                    stepData={stepData}
                    isCheckin={registerdUserData?.isCheckin ? registerdUserData?.isCheckin : false}
                />,
            );
            setQuestionnaireLoaded(false);
        }
    }, [questionnaireLoaded]);

    useEffect(() => {
        if (!videosprechstundeStepLoad) {
            if (apstate.jitsiDomain && tmstate.enrollData && tmstate.enrollData.enrollCode) {
                newSetOfStepsAndContent();
                setVideosprechstundeStepLoad(true);
            }
        }
    }, [apstate.jitsiDomain, tmstate.enrollData]);

    useEffect(() => {
        /* set the active step to first clickable unfinished journey step */
        if (isRegisteredUser()) {
            if ((tmstate?.enrollData?.isToday && !videochatHangup) || vsstate.callAccepted) {
                setVideochatStepAndContent();
                return;
            }
            if (allSteps.length > 1) {
                let foundIndex = -1;
                if (forceClosePDF) {
                    foundIndex = allSteps.findIndex((step) => {
                        if (step.active === true) {
                            return true;
                        }
                    });
                } else {
                    foundIndex = allSteps.findIndex((step) => {
                        if (step.typ === StepTyp.Journey && step.clickable && !step.completed) {
                            return true;
                        }
                    });
                }

                if (foundIndex >= 0) {
                    setActiveStep(foundIndex);
                    setForceClosePDF(false);
                    const con = getContent(foundIndex);
                    if (con) {
                        setActiveContent(con);
                    }
                }
            }
        } else if (props.useLoginContext && !activeStep) {
            const foundIndex = allSteps.findIndex((step) => {
                if (step.clickable && !step.completed) {
                    return true;
                }
            });
            if (foundIndex >= 0) {
                setActiveStep(foundIndex);
                const con = getContent(foundIndex);
                if (con) {
                    setActiveContent(con);
                }
            }
        }

        /* ##### change initial steps ##### */

        /* Wenn Fachrichtung eindeutig ist, wird Step noch angezeigt, aber uebersprungen */
        if (
            allSteps.length > 0 &&
            isExtendedPhysicians() &&
            props.journeyResponse &&
            props.journeyResponse.departments?.length == 1 &&
            !tmstate.selectedDepartment?.name
        ) {
            tmdispatch({
                type: 'SELECTDEPARTMENT',
                selectedDepartment: props.journeyResponse.departments[0],
            });
            const reloadData: IReloadPageData = {
                reload: true,
                withJourneyReload: false,
                nextActiveStep: 1,
                newStepValues: [
                    {
                        id: ID_CATEGORY_DEPARTMENT,
                        clickable: true,
                        active: false,
                        completed: true,
                        draft: false,
                    },
                    {
                        id: ID_CATEGORY_REASON,
                        clickable: true,
                        active: true,
                        completed: false,
                        draft: false,
                    },
                ],
            };
            setReloadPageData(reloadData);

            /* ########################## */
        }
        if (!conState.done) {
            const con = getContent(activeStep);
            if (con) {
                setActiveContent(con);
            }
        }
    }, [allSteps]);

    useEffect(() => {
        if (showLoadingSpinner) {
            if (reloadPageData.reload && reloadPageData.withJourneyReload) {
                getJourneyData();
            } else {
                newSetOfStepsAndContent();
            }

            setShowLoadingSpinner(false);
        }
    }, [showLoadingSpinner]);

    useEffect(() => {
        if (reloadPageData.reload) {
            reinitPage();
        }
    }, [reloadPageData]);

    useEffect(() => {
        if (cardReadClickedInPersData) {
            if (tmstate.personalData.eGK.length > 0 || tmstate.personalData.kvBarcode.length > 0) {
                setCardReadClickedInPersData(false);
            }
        } else if (!initPageDone) {
            if (tmstate.personalData?.lastName?.length > 0 || checkinstate.urlToCallResponse?.nameLast?.length > 0) {
                setInitPageDone(true);
            }
        }
    }, [tmstate.personalData, checkinstate.urlToCallResponse]);

    const reinitPage = () => {
        setShowLoadingSpinner(true);
    };

    const getOverwriteAppProps = () => {
        const overwriteProps = {
            allowAccountCreateDirect: false,
            allowDirectAppointmentsWithoutLogin: false,
            RegistrationVisibleFields: {
                ...apstate.RegistrationVisibleFields,
                insuranceNr: true,
            },
        };

        return overwriteProps;
    };

    const overwriteAppProps = () => {
        const overwriteProps = getOverwriteAppProps();
        apdispatch({
            type: 'SETAPPPROPS',
            ...apstate,
            ...overwriteProps,
        });
    };

    const getProperties = async () => {
        // const propRes = await getAppProperties();
        // if (propRes) {
        //     /* override appProperties */
        //     const overwriteProps = getOverwriteAppProps();
        //     const propResOverwritten = {
        //         ...propRes,
        //         ...overwriteProps,
        //     };
        //     apdispatch({
        //         type: 'SETAPPPROPS',
        //         ...apstate,
        //         ...propResOverwritten,
        //     });
        //     setDataFromAppProperties(propRes, apdispatch);
        // }
        /* override appProperties */
        const overwriteProps = getOverwriteAppProps();
        const propResOverwritten = {
            ...apstate,
            ...overwriteProps,
        };
        apdispatch({
            type: 'SETAPPPROPS',
            ...apstate,
            ...propResOverwritten,
        });
        // setDataFromAppProperties(propRes, apdispatch);
    };

    const setVideochatStepAndContent = () => {
        const foundIndex = allSteps.findIndex((step) => {
            if (step.id == ID_VIDEO) {
                return true;
            }
        });
        if (foundIndex >= 0) {
            setActiveStep(foundIndex);
            const con = getContent(foundIndex);
            if (con) {
                setActiveContent(con);
            }
            vsdispatch({ type: 'ONDISCONNECT' });
        }
    };

    const newSetOfStepsAndContent = () => {
        getInitialSteps();
        let nextStep = 0;
        if (reloadPageData.reload) {
            nextStep = reloadPageData.nextActiveStep;
        }
        const con = getContent(nextStep);
        if (con) {
            setActiveContent(con);
        }
        setReloadPageData({
            reload: false,
            withJourneyReload: false,
            nextActiveStep: 0,
            newStepValues: [
                {
                    id: '',
                    active: false,
                    clickable: false,
                    completed: false,
                    draft: false,
                },
            ],
        });
    };

    const getNextUnfinishedStepNumberWithoutVideo = (currentStepNumber: number) => {
        let endReached = false;
        for (let i = currentStepNumber; i < allSteps.length; i++) {
            const nextStep = i + 1;
            if (nextStep >= allSteps.length) {
                endReached = true;
                break;
            } else {
                if (!allSteps[nextStep].completed && allSteps[nextStep].id != ID_VIDEO) {
                    return nextStep;
                }
            }
        }

        if (endReached) {
            for (let i = 0; i < currentStepNumber; i++) {
                if (!allSteps[i].completed && allSteps[i].id != ID_VIDEO) {
                    return i;
                }
            }
        }

        return 0;
    };

    const getNextUnfinishedStepNumber = (currentStepNumber: number) => {
        let endReached = false;
        for (let i = currentStepNumber; i < allSteps.length; i++) {
            const nextStep = i + 1;
            if (nextStep >= allSteps.length) {
                endReached = true;
                break;
            } else {
                if (!allSteps[nextStep].completed) {
                    return nextStep;
                }
            }
        }

        if (endReached) {
            for (let i = 0; i < currentStepNumber; i++) {
                if (!allSteps[i].completed) {
                    return i;
                }
            }
        }

        return 0;
    };

    const getActiveStep = () => {
        let st = -1;
        setActiveStep((step) => {
            st = step;
            return step;
        });

        return st;
    };

    const changeStep = (
        currentSteps: IStepperCheckInStep[],
        newValues: IChangeStep,
        setAllOtherStepsInactive: boolean,
    ) => {
        if (currentSteps.length > 0) {
            const allStepsCopy = [...currentSteps];
            for (let i = 0; i < allStepsCopy.length; i++) {
                if (allStepsCopy[i].id === newValues.id) {
                    if (newValues.completed) {
                        allStepsCopy[i].completed = newValues.completed;
                    }
                    if (newValues.draft) {
                        allStepsCopy[i].draft = newValues.draft;
                    }
                    if (newValues.active) {
                        allStepsCopy[i].active = newValues.active;
                    }
                    if (newValues.clickable) {
                        allStepsCopy[i].clickable = newValues.clickable;
                    }
                } else {
                    if (setAllOtherStepsInactive) {
                        allStepsCopy[i].active = false;
                    }
                }
            }

            return allStepsCopy;
        }
    };

    const setAllStepsGreaterInactive = (stepNumber: number) => {
        if (allSteps.length > 0) {
            const allStepsCopy = [...allSteps];
            const startNumber = stepNumber + 1;
            for (let i = startNumber; i < allStepsCopy.length; i++) {
                allStepsCopy[i].active = false;
                allStepsCopy[i].clickable = false;
            }
            allStepsCopy[stepNumber].active = true;
            allStepsCopy[stepNumber].clickable = true;
            setAllSteps(allStepsCopy);
        }
    };

    // const getGDTData = async () => {
    //     const gdtData = await getParkedGDT(state.gdt.id);
    //     return gdtData;
    // };

    const getJourneyData = async () => {
        if (registerdUserData?.reason) {
            const auth = getFullOTPAuthorization(
                'PCP',
                registerdUserData.otp,
                registerdUserData.userId,
                registerdUserData.t0,
            );
            const questionnaireJourneyResponse = await getQuestionnaireJourney(
                registerdUserData?.reason,
                auth,
                checkinstate.wpName,
            );
            if (questionnaireJourneyResponse) {
                mySetJourneyRessponse(questionnaireJourneyResponse);
            }
        }
    };

    const getJourneyDataForHOME = async (reason) => {
        const questionnaireJourneyResponse = await getQuestionnaireJourney(reason, state.sessionId);
        if (questionnaireJourneyResponse) {
            mySetJourneyRessponse(questionnaireJourneyResponse);
        }
    };

    const isCheckInWorkplace = () => {
        if (checkinstate.wpName?.length > 0) {
            return true;
        }

        return false;
    };

    const isPerVideo = () => {
        /*
        if (tmstate.selectedAppointmentType == 'per Video') {
            return true;
        }
        */

        return false;
    };

    // const isCardReadDone = () => {
    //     if (tmstate.personalData.eGK.length > 0) {
    //         return true;
    //     }

    //     return false;
    // };

    const isRegisteredUser = () => {
        if (authData.userId > 0 && authData.otp.length > 0 && authData.t0.length > 0) {
            return true;
        }
        return false;
    };

    const handleQuestFinished = (currentActiveStep) => {
        const reloadData: IReloadPageData = {
            reload: true,
            withJourneyReload: true,
            nextActiveStep: getNextUnfinishedStepNumber(currentActiveStep),
        };
        setReloadPageData(reloadData);
    };

    const handleQuestSave = () => {
        setClickedQuestSave(true);
    };

    const handleQuestPDFClose = (currentActiveStep: number) => {
        const reloadData: IReloadPageData = {
            reload: true,
            withJourneyReload: true,
            nextActiveStep: getNextUnfinishedStepNumber(currentActiveStep),
        };
        setReloadPageData(reloadData);
    };

    const handleRegistrationFinished = (response: IAuthData) => {
        if (response) {
            const authData: IAuthData = {
                finalURL: response.finalURL,
                otp: response.otp,
                t0: response.t0,
                userId: response.userId,
            };

            setAuthData(authData);
        }

        setActiveStep(1); //Now sign Datenschutz
    };

    const onTelephoneCallOff = () => {
        setShowTelephoneModal(false);
    };

    const onTelephoneCallAccept = () => {
        setShowTelephoneModal(false);
    };

    const onTelephoneHide = () => {
        stopRingTone();
        setShowTelephoneModal(false);
    };

    const getJourneyFinalURLId = (id: string) => {
        let finalURL = '';
        journeyData?.forms.forEach((form) => {
            if (id === form.id) {
                if (form.finalURL) {
                    finalURL = form.finalURL;
                }
            }
        });

        const documentId = finalURL.split('DiagnosticReport/')[1];
        return documentId;
    };

    const getJourneyDraftURLId = (id: string) => {
        let draftURL = '';
        journeyData?.forms.forEach((form) => {
            if (id === form.id) {
                if (form.draftURL) {
                    draftURL = form.draftURL;
                }
            }
        });

        return draftURL;
    };

    const handleClickPDFDocment = async (auth: string, documentId: string) => {
        const response = await getDiagnosticReport(auth, documentId);
        const [modalTitle, pdfsrc, isSignable, isSigned, signPosData] = getPDFDataFromDiagnosticReportResponse(
            response,
            '',
            '',
        );

        setPdfModalShow({
            show: true,
            modalTitle: modalTitle,
            pdfData: <IFramePDF src={pdfsrc} />,
            isSignable: isSignable === 'true' ? true : false,
            isSigned: isSigned === 'true' ? true : false,
            diagnosticReportData: response.data,
        });
    };

    const handleClickQuestionnaireDraft = async (
        auth: string,
        questionnaireId: string,
        stepData: IQuestionnaireStepData,
        stepName: string,
    ) => {
        let answers: IQAnswers[] = [];

        const questionnaireAuth: IQuestionnaireWL = {
            type: 'Questionnaire',
            baseURL: props.newAccountHashParams?.key,
            name: stepName,
            otp: auth,
            t0: authData.t0,
            userId: authData.userId.toString(),
            stepData: stepData,
        };

        const selectedResource = await getQuestionnaireOTPAuth(questionnaireAuth);
        if (selectedResource) {
            const rep: IQData = selectedResource.data;
            setQuestionnaireData(rep);
            const qResource = getQuestionnaireResourceFromRequest(rep);
            const contentRes = await getQuestionnaireContent('PCP ' + auth, questionnaireId, alertdispatch);

            // set correct questionnaire id
            const qId = questionnaireId.split('/')[1];
            selectedResource.id = qId;
            selectedResource.status = contentRes.data.status; //in-progress

            setQuestionnaireBody(contentRes.data);

            answers = loadQuestionnaireAnswers(contentRes.data);

            setSelectedQuestionnaireAnswers(answers);

            qResource.status = contentRes.data.status; //in-progress
            setSelectedQuestionnaire(qResource);

            setQuestionnaireLoaded(true);
        }
    };

    // const handleClickAppointmentRegisteredUser = () => {
    //     if (journeyData?.makros && journeyData.makros.length > 0) {
    //         const auth = getFullOTPAuthorization('PCP', authData.otp, authData.userId, authData.t0);
    //         setActiveContent(
    //             <StepperTerminierung
    //                 makrosToUse={journeyData.makros}
    //                 checkInVariant={true}
    //                 handleAppointmentsCancelClick={handleAppointmentsCancelClick}
    //                 handleAppointmentsFinishClick={handleAppointmentsFinishClick}
    //             />,
    //         );
    //     }
    // };

    // const handleClickAppointmentNewUser = (journeyData: IQuestionnaireJourneyData) => {
    //     if (journeyData?.makros && journeyData.makros.length > 0) {
    //         if (checkinstate.urlToCallResponse.eGK && checkinstate.urlToCallResponse.eGK.length > 0) {
    //             setActiveContent(
    //                 <StepperTerminierungWL
    //                     makrosToUse={journeyData.makros}
    //                     checkInVariant={true}
    //                     soforttermin={true}
    //                     terminsuchen={false}
    //                     embed={true}
    //                     reason={props.newAccountHashParams?.reason}
    //                     handleAppointmentsCancelClick={handleAppointmentsCancelClick}
    //                     handleAppointmentsFinishClick={handleAppointmentsFinishClick}
    //                 />,
    //             );
    //         } else {
    //             setActiveContent(
    //                 <StepperTerminierungWL
    //                     makrosToUse={journeyData.makros}
    //                     checkInVariant={true}
    //                     reason={props.newAccountHashParams?.reason}
    //                     handleAppointmentsCancelClick={handleAppointmentsCancelClick}
    //                     handleAppointmentsFinishClick={handleAppointmentsFinishClick}
    //                 />,
    //             );
    //         }
    //     }
    // };

    const getAndSetPersData = (authData: IAuthData) => {
        const auth = getFullOTPAuthorization('PCP', authData.otp, authData.userId, authData.t0);
        getAndSetPersonalData(auth, authData.userId.toString(), '');
    };

    const showQuestionnaireEntries = (stepContent: number) => {
        if (allSteps.length > 0) {
            // const activeHashParams = allSteps[activeStep].hashParams;
            const stepData: IQuestionnaireStepData = {
                activeStepNumber: stepContent,
                handleQuestionnaireFinished: handleQuestFinished,
                handleQuestionnairePDFClose: handleQuestPDFClose,
                handleQuestionnaireSave: handleQuestSave,
            };

            // const otp = authData.otp + '&userId=' + authData.userId + '&t0=' + authData.t0;
            const otp = getFullOTPAuthorization('', authData.otp, authData.userId, authData.t0);
            const step = allSteps[stepContent];

            const documentId = getJourneyFinalURLId(step.id);
            const draftId = getJourneyDraftURLId(step.id);
            if (documentId && documentId.length > 0) {
                /* it's a pdf document */
                handleClickPDFDocment('PCP ' + otp, documentId);
            } else if (draftId && draftId.length > 0) {
                /* it's a draft questionnaire */
                handleClickQuestionnaireDraft(otp, draftId, stepData, step.id);
            } else {
                return (
                    <FragebogenDetail
                        type="Questionnaire"
                        key={props.newAccountHashParams?.key}
                        baseURL={props.newAccountHashParams?.key}
                        name={step.id}
                        otp={otp}
                        t0={authData.t0}
                        userId={authData.userId.toString()}
                        stepData={stepData}
                        isCheckin={registerdUserData?.isCheckin ? registerdUserData?.isCheckin : false}
                    />
                );
            }
        }
    };

    const handlePossibleChangeOfNextSteps = (stepNumberClicked: number) => {
        let setInactive = false;
        const clickedId = allSteps[stepNumberClicked].id;
        if (
            clickedId === ID_CATEGORY_DEPARTMENT ||
            clickedId === ID_CATEGORY_REASON ||
            clickedId === ID_CATEGORY_PHYSICIAN ||
            clickedId === ID_APPOINTMENT_SELECTION
        ) {
            setInactive = true;
        }

        if (setInactive) {
            setAllStepsGreaterInactive(stepNumberClicked);
        }
    };

    const handleStepClick = (stepNumberClicked: number) => {
        setActiveStep(stepNumberClicked);
        setStepClicked(true);
        handlePossibleChangeOfNextSteps(stepNumberClicked);
    };

    const handleLogout = () => {
        if (!props.useLoginContext) {
            handleLogoutCheckIn();
        }

        if (isCheckInWorkplace()) {
            navigate(Paths.SPLASHSCREEN_CHECKIN + '?wpName=' + checkinstate.wpName);
        } else {
            // navigate(Paths.SPLASHSCREEN_LOGGEDOUT);
            navigate(Paths.HOME);
            // window.location.reload();
        }
    };

    const showAvatarIcon = () => {
        if (isRegisteredUser()) {
            return true;
        }
        return false;
    };

    const showLoginIcon = () => {
        if (isRegisteredUser()) {
            return false;
        } else if (isLoggedIn) {
            return false;
        } else if (apstate.allowAccountInAnmeldeworkflow) {
            return apstate.allowAccountInAnmeldeworkflow;
        }

        return false;
    };

    const handleProfileClick = () => {
        if (isRegisteredUser()) {
            const reloadData: IReloadPageData = {
                reload: true,
                withJourneyReload: false,
                nextActiveStep: 0,
                newStepValues: [
                    {
                        id: ID_MYDATA,
                        clickable: true,
                        active: true,
                        completed: false,
                        draft: false,
                    },
                ],
            };
            setReloadPageData(reloadData);
        } else {
            setActiveContent(<PersonalDataSummary />);
        }
    };

    const showAccountInAnmeldeworkflow = () => {
        if (apstate.allowAccountInAnmeldeworkflow) {
            return !isLoggedIn;
        }
        return false;
    };

    const handleLoginIconClick = () => {
        setLoginModalShow({
            show: true,
        });
    };

    const handleOnCardReadClick = () => {
        setCardReadClickedInPersData(true);
    };

    const handleFinalCheckInClick = () => {
        if (props.useLoginContext) {
            return;
        }
        handleLogout();
    };

    const handleClosePDFModal = () => {
        setPdfModalShow({
            show: false,
            modalTitle: '',
            pdfData: '',
            isSignable: false,
            isSigned: false,
        });
        setForceClosePDF(true);
        const reloadData: IReloadPageData = {
            reload: true,
            withJourneyReload: true,
            nextActiveStep: getNextUnfinishedStepNumber(activeStep),
        };
        setReloadPageData(reloadData);
    };

    const handleCloseLoginModal = () => {
        setLoginModalShow({
            show: false,
        });
    };

    const onHideAlertModal = () => {
        if (window.opener !== null) {
            window.close();
        } else {
            handleLogout();
        }
    };

    const handleOnVideoHangup = () => {
        // const nextStep = getNextUnfinishedStepNumberWithoutVideo(0);
        // allSteps[nextStep];
        // const reloadData: IReloadPageData = {
        //     reload: true,
        //     withJourneyReload: false,
        //     nextActiveStep: nextStep,
        //     newStepValues: [
        //         {
        //             id: ID_VIDEO,
        //             clickable: true,
        //             active: false,
        //             completed: true,
        //             draft: false,
        //         },
        //         {
        //             id: allSteps[nextStep].id,
        //             clickable: allSteps[nextStep].clickable,
        //             active: true,
        //             completed: allSteps[nextStep].completed,
        //             draft: allSteps[nextStep].draft,
        //         },
        //     ],
        // };

        setVideochatHangup(true);
        vsdispatch({ type: 'ONDISCONNECT' });

        showAlertMessage({
            alertTitle: 'Videotermin',
            alertTxt: 'Die Videosprechstunde wurde beendet',
            alertType: AlertType.info,
            onHide: AlertOnHide.custom,
            onHideCustom: onHideAlertModal,
            alertdispatch: alertdispatch,
        });

        /*
        vsdispatch({
            type: 'SETJITSIALLOWED',
            jitsiAllowed: false,
        });
        */

        // setReloadPageData(reloadData);
    };

    const getAuthorizeLoginData = async (username: string, password: string) => {
        const authorizeLoginProps: IAuthorizeAsync = {
            username: username,
            password: password,
        };
        const loginData = await authorizeAsync(authorizeLoginProps);
        if (!loginData || loginData.error) {
            showAlertMessage({
                alertTitle: 'Anmelden',
                alertTxt: loginData.error,
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
                alertdispatch: alertdispatch,
            });
        } else {
            getAndSetPersonalData(loginData.sessionId, loginData.userId, loginData.practitionerRoleId);
            setIsLoggedIn(true);
            dispatch({
                type: 'LOGIN',
                userId: loginData.userId,
                userName: username,
                sessionId: loginData.sessionId,
                securityItems: {
                    CHARTS: loginData.SecurityItems[securityItemNames.CHARTS] === 'true' ? true : false,
                    CHARTS_WEIGHT: loginData.SecurityItems[securityItemNames.CHARTS_WEIGHT] === 'true' ? true : false,
                    CHARTS_FATMASS: loginData.SecurityItems[securityItemNames.CHARTS_FATMASS] === 'true' ? true : false,
                    CHARTS_BLOODSUGAR:
                        loginData.SecurityItems[securityItemNames.CHARTS_BLOODSUGAR] === 'true' ? true : false,
                    CHARTS_FLI: loginData.SecurityItems[securityItemNames.CHARTS_FLI] === 'true' ? true : false,
                    CHARTS_SAM: loginData.SecurityItems[securityItemNames.CHARTS_SAM] === 'true' ? true : false,
                    CHARTS_ACTIVITY:
                        loginData.SecurityItems[securityItemNames.CHARTS_ACTIVITY] === 'true' ? true : false,
                    TABLE_VAL: loginData.SecurityItems[securityItemNames.TABLE_VAL] === 'true' ? true : false,
                    DOKUMENT: loginData.SecurityItems[securityItemNames.DOKUMENT] === 'true' ? true : false,
                    FOTOS: loginData.SecurityItems[securityItemNames.FOTOS] === 'true' ? true : false,
                    FRAGEBOGEN: loginData.SecurityItems[securityItemNames.FRAGEBOGEN] === 'true' ? true : false,
                    MEDIKATION: loginData.SecurityItems[securityItemNames.MEDIKATION] === 'true' ? true : false,
                    TAGESPLAN: loginData.SecurityItems[securityItemNames.TAGESPLAN] === 'true' ? true : false,
                    TERMINE: loginData.SecurityItems[securityItemNames.TERMINE] === 'true' ? true : false,
                    VIDEO: loginData.SecurityItems[securityItemNames.VIDEO] === 'true' ? true : false,
                    JOURNEY: loginData.SecurityItems[securityItemNames.JOURNEY] === 'true' ? true : false,
                    TODO: loginData.SecurityItems[securityItemNames.TODO]
                        ? loginData.SecurityItems[securityItemNames.TODO]
                        : [],
                    TAB_ORDER: loginData.SecurityItems[securityItemNames.TAB_ORDER]
                        ? loginData.SecurityItems[securityItemNames.TAB_ORDER]
                        : '',
                },
                iceServers: loginData.iceServers,
                possibleMacros: loginData.PossibleMacros,
                departments: loginData.departments ? loginData.departments : [],
                activeStudies: loginData.activeStudies,
                activeProfile: PROFILE_HOME,
                activeUserId: loginData.userId,
                termsHTML: '',
                privacyHTML: '',
                extraLinks: loginData.extraLinks,
                possibleStudies: loginData.possibleStudies ? loginData.possibleStudies : [],
                addConsignationsToAsk: [],
            });
        }
    };

    const handleLogin = (username: string, password: string) => {
        setLoginModalShow({
            show: false,
        });
        getAuthorizeLoginData(username, password);
    };

    const handleDepartmentClick = () => {
        const reloadData: IReloadPageData = {
            reload: true,
            withJourneyReload: false,
            nextActiveStep: activeStep + 1,
            newStepValues: [
                {
                    id: ID_CATEGORY_DEPARTMENT,
                    clickable: true,
                    active: false,
                    completed: true,
                    draft: false,
                },
                {
                    id: ID_CATEGORY_REASON,
                    clickable: true,
                    active: true,
                    completed: false,
                    draft: false,
                },
            ],
        };
        setReloadPageData(reloadData);
    };

    const handleLocationClick = async (location: ICodeName) => {
        await getJourneyDataForHOME(location.code);
        const reloadData: IReloadPageData = {
            reload: true,
            withJourneyReload: false,
            nextActiveStep: activeStep + 1,
            newStepValues: [
                {
                    id: ID_CATEGORY_LOCATION,
                    clickable: true,
                    active: false,
                    completed: true,
                    draft: false,
                },
                {
                    id: ID_CATEGORY_DEPARTMENT,
                    clickable: true,
                    active: true,
                    completed: false,
                    draft: false,
                },
            ],
        };
        setReloadPageData(reloadData);
    };

    const handleReasonClick = () => {
        let reloadData: IReloadPageData = {
            reload: true,
            withJourneyReload: false,
            nextActiveStep: getStepId(ID_CATEGORY_PHYSICIAN),
            newStepValues: [
                {
                    id: ID_CATEGORY_REASON,
                    clickable: true,
                    active: false,
                    completed: true,
                    draft: false,
                },
                {
                    id: ID_CATEGORY_PHYSICIAN,
                    clickable: true,
                    active: true,
                    completed: false,
                    draft: false,
                },
            ],
        };
        if (isExtendedPhysicians()) {
            reloadData = {
                reload: true,
                withJourneyReload: false,
                // nextActiveStep: getActiveStep() + 1,
                nextActiveStep: getStepId(ID_APPOINTMENT_SELECTION_WITH_PHYSICIAN),
                newStepValues: [
                    {
                        id: ID_CATEGORY_REASON,
                        clickable: true,
                        active: false,
                        completed: true,
                        draft: false,
                    },
                    {
                        id: ID_APPOINTMENT_SELECTION_WITH_PHYSICIAN,
                        clickable: true,
                        active: true,
                        completed: false,
                        draft: false,
                    },
                ],
            };
        } else if (apstate.Appointment?.skipBehandlersuche) {
            reloadData = {
                reload: true,
                withJourneyReload: false,
                nextActiveStep: getStepId(ID_APPOINTMENT_SELECTION),
                newStepValues: [
                    {
                        id: ID_CATEGORY_REASON,
                        clickable: true,
                        active: false,
                        completed: true,
                        draft: false,
                    },
                    {
                        id: ID_APPOINTMENT_SELECTION,
                        clickable: true,
                        active: true,
                        completed: false,
                        draft: false,
                    },
                ],
            };
        }

        setReloadPageData(reloadData);
    };

    const handlePhysicianClick = (currentActiveStep: number) => {
        /* Ohne CheckInDesk -> Termin buchen Step kommt mit */
        let reloadData: IReloadPageData = {
            reload: true,
            withJourneyReload: false,
            nextActiveStep: currentActiveStep + 1,
            newStepValues: [
                {
                    id: ID_CATEGORY_PHYSICIAN,
                    clickable: true,
                    active: false,
                    completed: true,
                    draft: false,
                },
                {
                    id: ID_APPOINTMENT_SELECTION,
                    clickable: true,
                    active: true,
                    completed: false,
                    draft: false,
                },
            ],
        };
        /* Kein Termin buchen wenn an einem CheckInDesk -> wpName kommt mit -> Soforttermin */
        if (isCheckInWorkplace()) {
            reloadData = {
                reload: true,
                withJourneyReload: false,
                nextActiveStep: currentActiveStep + 1,
                newStepValues: [
                    {
                        id: ID_PERSONALDATA,
                        clickable: true,
                        active: true,
                        completed: false,
                        draft: false,
                    },
                ],
            };
        } else if (isPerVideo()) {
            reloadData = {
                reload: true,
                withJourneyReload: false,
                nextActiveStep: currentActiveStep + 2,
                newStepValues: [
                    {
                        id: ID_PERSONALDATA,
                        clickable: true,
                        active: true,
                        completed: false,
                        draft: false,
                    },
                ],
            };
        }

        setReloadPageData(reloadData);
    };

    const handleTimeslotClick = (currentActiveStep: number, hdBooking?: string) => {
        let newId;
        if (props.isPhysicianLogin) {
            newId = ID_PERSONALDATA;
        } else if (props.useLoginContext) {
            newId = ID_BOOKING;
        } else {
            newId = ID_PERSONALDATA;
        }
        let reloadData: IReloadPageData = {
            reload: true,
            withJourneyReload: false,
            nextActiveStep: currentActiveStep + 1,
            newStepValues: [
                {
                    id: ID_APPOINTMENT_SELECTION,
                    clickable: true,
                    active: false,
                    completed: true,
                    draft: false,
                },
                {
                    id: newId,
                    clickable: true,
                    active: true,
                    completed: false,
                    draft: false,
                },
            ],
        };

        if (isExtendedPhysicians()) {
            reloadData = {
                reload: true,
                withJourneyReload: false,
                nextActiveStep: currentActiveStep + 1,
                newStepValues: [
                    {
                        id: ID_APPOINTMENT_SELECTION_WITH_PHYSICIAN,
                        clickable: true,
                        active: false,
                        completed: true,
                        draft: false,
                    },
                    {
                        id: newId,
                        clickable: true,
                        active: true,
                        completed: false,
                        draft: false,
                    },
                ],
            };
        }
        setReloadPageData(reloadData);
        tmdispatch({
            type: 'TIMESLOTCLICKED',
            timeslotClicked: false,
        });
    };

    const handlePersonalDataNext = (currentActiveStep: number) => {
        const reloadData: IReloadPageData = {
            reload: true,
            withJourneyReload: false,
            nextActiveStep: currentActiveStep + 1,
            newStepValues: [
                {
                    id: ID_PERSONALDATA,
                    clickable: true,
                    active: false,
                    completed: true,
                    draft: false,
                },
                {
                    id: ID_BOOKING,
                    clickable: true,
                    active: true,
                    completed: false,
                    draft: false,
                },
            ],
        };
        setReloadPageData(reloadData);
    };

    const handleMyAppointmentNext = (currentActiveStep: number) => {
        const reloadData: IReloadPageData = {
            reload: true,
            withJourneyReload: false,
            nextActiveStep: getNextUnfinishedStepNumber(currentActiveStep),
        };
        setReloadPageData(reloadData);
    };

    const handleUploadNext = (currentActiveStep: number) => {
        const reloadData: IReloadPageData = {
            reload: true,
            withJourneyReload: false,
            nextActiveStep: getNextUnfinishedStepNumber(currentActiveStep),
            newStepValues: [
                {
                    id: ID_FINALCHECKIN,
                    clickable: true,
                    active: true,
                    completed: false,
                    draft: false,
                },
            ],
        };
        setReloadPageData(reloadData);
    };

    const updateEnrollment = async () => {
        const enrollData: IEnrollData = await getEnrollData();
        if (tmstate.personalData.eGK.length > 0 || tmstate.personalData.kvBarcode.length > 0) {
            enrollData.eGK = tmstate.personalData.eGK;
            enrollData.kvBarcode = tmstate.personalData.kvBarcode;
        }
        await updateEnroll(enrollData, checkinstate.reason);
    };

    const getStepId = (stepCode: string) => {
        for (let i = 0; i < allSteps.length; i++) {
            if (allSteps[i].id == stepCode) {
                return i;
            }
        }
        return -1;
    };
    const handlePersonalDataNextAfterCardRead = (currentActiveStep: number) => {
        // updateEnrollment();
        setUpdateEnrollmentRequest(true);
        const reloadData: IReloadPageData = {
            reload: true,
            withJourneyReload: false,
            nextActiveStep: currentActiveStep + 1,
            newStepValues: [
                {
                    id: ID_MYAPPOINTMENTS,
                    clickable: true,
                    active: true,
                    completed: true,
                    draft: false,
                },
            ],
        };
        setReloadPageData(reloadData);
    };

    const handleBookingClick = (response: any) => {
        if (props.isPhysicianLogin) {
            dispatch({
                type: 'SETACTIVEUSERID',
                activeUserId: response.userId,
            });

            const newPatient: IActivePatients = {
                patientId: response.userId,
                nameLast: response.patNameLast,
                nameFirst: response.patNameFirst,
                birthDate: response.patBirthDate,
            };

            setNewPatient(newPatient);
            // pushActivePatient(response.userId, state.sessionId);
            navigate(Paths.FRAGEBOGEN);
        }
        setRegisteredUserData({
            reason: response.reason,
            userId: response.userId,
            t0: response.t0,
            otp: response.otp,
            isCheckin: true,
        });
        if (response.enrollCode) {
            tmdispatch({
                type: 'SETENROLLCODE',
                enrollData: {
                    enrollCode: response.enrollCode,
                },
            });
        }

        /* get AppProperties (jitsiDomain,...) to set Steps correctly in next Screen */
        getProperties();
    };
    const handleBookingCancelClick = () => {
        const resource_1 = getSelectedResource(1);
        if (resource_1) {
            unblockAppointment(resource_1);
        }
        if (props.useLoginContext) {
            navigate(Paths.TERMINE);
        } else {
            handleLogout();
        }
    };

    /**
     * ----------------------------------------------
     * ############ BEGIN STEPS AND CONTENT ###############
     * ----------------------------------------------
     */

    const ID_MYDATA = '__MYDATA'; // Meine Daten
    const ID_MYAPPOINTMENTS = '__MYAPPOINTMENTS'; // Meine Termine
    const ID_FINALCHECKIN = '__FINALCHECKIN'; // CheckIn
    const ID_CATEGORY_DEPARTMENT = '__CATDEPARTMENT'; // Fachrichtung
    const ID_CATEGORY_REASON = '__CATREASON'; // Besuchsgrund
    const ID_CATEGORY_PHYSICIAN = '__CATPHYSICIAN'; // Arztauswahl
    const ID_APPOINTMENT_SELECTION = '__APPOINTMENTSELECTION'; // Termin buchen
    const ID_APPOINTMENT_SELECTION_WITH_PHYSICIAN = '__APPOINTMENTSELECTION_WITH_PHYSICIAN'; // Termin buchen und Arzt Auswahl in einem Step
    const ID_PERSONALDATA = '__PERSONALDATA'; // Daten eingeben
    const ID_BOOKING = '__BOOKING'; // Termin bestätigen
    const ID_VIDEO = '__VIDEO'; // Video - Fenster
    const ID_CATEGORY_LOCATION = '__LOCATION'; // Standort Auswahl

    const ID_REGISTRATION = '__REGISTRATION'; // Registrierung
    const ID_UPLOAD = '__UPLOAD'; // Briefe und Befunde
    const ID_ROUTE = '__ROUTE';

    const getRestHeight = () => {
        try {
            const navbar = document.body.querySelector('nav.navbar');
            const stepbar = document.getElementById('steppercheckinparent');
            const vh = screenSize.height;
            if (navbar && stepbar) {
                const resHeight = vh - navbar.clientHeight - stepbar?.offsetHeight - 10;
                return resHeight + 'px';
            }
        } catch (ex) {}
        return '100%';
    };
    const getInitialSteps = () => {
        let steps: IStepperCheckInStep[] = [];

        /* handle possible change of step */
        let newStepsToSet = false;
        if (reloadPageData.newStepValues && reloadPageData.newStepValues.length > 0) {
            reloadPageData.newStepValues.forEach((newStep) => {
                if (newStep.id?.length > 0) {
                    const newSteps = changeStep(steps.length > 0 ? steps : allSteps, newStep, true);
                    if (newSteps) {
                        steps = newSteps;
                        newStepsToSet = true;
                    }
                    setActiveStep(reloadPageData.nextActiveStep);
                }
            });
        }

        if (!newStepsToSet) {
            let journeyResp = props.journeyResponse;
            if (journeyData !== undefined) {
                journeyResp = journeyData;
            }

            const videoIsActive =
                apstate.jitsiDomain &&
                tmstate.enrollData &&
                tmstate.enrollData.enrollCode &&
                tmstate.enrollData.isToday &&
                !videochatHangup;

            if (isRegisteredUser()) {
                /* Meine DATEN */
                steps.push({
                    id: ID_MYDATA,
                    name: 'Meine Daten',
                    typ: StepTyp.Normal,
                    completed: true,
                    draft: false,
                    active: videoIsActive ? false : true,
                    clickable: true,
                });

                /* Meine Termine */
                steps.push({
                    id: ID_MYAPPOINTMENTS,
                    name: 'Meine Termine',
                    typ: StepTyp.Normal,
                    completed: true,
                    draft: false,
                    active: false,
                    clickable: true,
                });

                /* Rest */
                journeyResp?.forms.forEach((form) => {
                    let finished = false;
                    let draft = false;
                    if (form.finalURL) {
                        if (form.isSignable !== undefined && form.isSignable === true) {
                            if (form.isSigned !== undefined && form.isSigned === true) {
                                finished = true;
                            }
                        } else {
                            finished = true;
                        }
                    } else if (form.draftURL) {
                        draft = true;
                    }
                    steps.push({
                        id: form.id,
                        name: form.name,
                        typ: StepTyp.Journey,
                        completed: finished,
                        draft: draft,
                        active: false,
                        clickable: true,
                        url: form.url,
                    }); //all questionnaire steps
                });

                /* CheckIn (wenn an einem workplace) */
                if (isCheckInWorkplace()) {
                    steps.push({
                        id: ID_FINALCHECKIN,
                        name: 'CheckIn',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: true,
                    });
                } else if (apstate.jitsiDomain && tmstate.enrollData && tmstate.enrollData.enrollCode) {
                    steps.push({
                        id: ID_VIDEO,
                        name: 'Videosprechstunde',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: videoIsActive ? true : false,
                        clickable: true,
                    }); //all questionnaire steps
                }

                /**
                 * ------------------------------
                 * additional adaptions to steps
                 * ------------------------------
                 */

                /* set the active step to first clickable unfinished journey step */
                for (let i = 0; i < steps.length; i++) {
                    if (steps[i].typ === StepTyp.Journey && steps[i].clickable && !steps[i].completed) {
                        steps[i].active = true;
                        break;
                    }
                }

                /* set next active step after reload and all others not to avoid double active
                    (e.g. pdf closed -> handleClosePDF() -> getNextUnfinishedStepNumber() -> reload)
                    */
                if (reloadPageData.reload) {
                    for (let i = 0; i < steps.length; i++) {
                        steps[i].active = false;
                    }
                    steps[reloadPageData.nextActiveStep].active = true;
                }
            } else if (props.isPhysicianLogin) {
                if (isExtendedPhysicians()) {
                    if (hasAnmeldContexts()) {
                        steps.push({
                            id: ID_CATEGORY_LOCATION,
                            name: 'Standort',
                            typ: StepTyp.Normal,
                            completed: false,
                            draft: false,
                            active: false,
                            clickable: true,
                        });
                    }
                    steps.push({
                        id: ID_CATEGORY_DEPARTMENT,
                        name: 'Fachrichtung',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: true,
                    });
                    steps.push({
                        id: ID_CATEGORY_REASON,
                        name: 'Besuchsgrund',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                    steps.push({
                        id: ID_APPOINTMENT_SELECTION_WITH_PHYSICIAN,
                        name: 'Termin buchen',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                    steps.push({
                        id: ID_PERSONALDATA,
                        name: 'Daten eingeben',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                    steps.push({
                        id: ID_BOOKING,
                        name: 'Termin bestätigen',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                } else {
                    steps.push({
                        id: ID_CATEGORY_DEPARTMENT,
                        name: 'Fachrichtung',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: true,
                    });
                    steps.push({
                        id: ID_CATEGORY_REASON,
                        name: 'Besuchsgrund',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                    if (!apstate.Appointment?.skipBehandlersuche) {
                        steps.push({
                            id: ID_CATEGORY_PHYSICIAN,
                            name: 'Behandler',
                            typ: StepTyp.Normal,
                            completed: false,
                            draft: false,
                            active: false,
                            clickable: false,
                        });
                    }

                    steps.push({
                        id: ID_APPOINTMENT_SELECTION,
                        name: 'Termin buchen',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                    steps.push({
                        id: ID_PERSONALDATA,
                        name: 'Daten eingeben',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                    steps.push({
                        id: ID_BOOKING,
                        name: 'Termin bestätigen',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                }
            } else if (props.useLoginContext) {
                if (isExtendedPhysicians()) {
                    if (hasAnmeldContexts()) {
                        steps.push({
                            id: ID_CATEGORY_LOCATION,
                            name: 'Standort',
                            typ: StepTyp.Normal,
                            completed: false,
                            draft: false,
                            active: false,
                            clickable: true,
                        });
                    }
                    steps.push({
                        id: ID_CATEGORY_DEPARTMENT,
                        name: 'Fachrichtung',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: true,
                    });
                    steps.push({
                        id: ID_CATEGORY_REASON,
                        name: 'Besuchsgrund',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                    steps.push({
                        id: ID_APPOINTMENT_SELECTION_WITH_PHYSICIAN,
                        name: 'Termin buchen',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                    steps.push({
                        id: ID_BOOKING,
                        name: 'Termin bestätigen',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                    /* physicians and timeslots in seperated steps*/
                } else {
                    if (hasAnmeldContexts()) {
                        steps.push({
                            id: ID_CATEGORY_LOCATION,
                            name: 'Standort',
                            typ: StepTyp.Normal,
                            completed: false,
                            draft: false,
                            active: false,
                            clickable: true,
                        });
                    }
                    steps.push({
                        id: ID_CATEGORY_DEPARTMENT,
                        name: 'Fachrichtung',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: true,
                    });
                    steps.push({
                        id: ID_CATEGORY_REASON,
                        name: 'Besuchsgrund',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                    if (!apstate.Appointment?.skipBehandlersuche) {
                        steps.push({
                            id: ID_CATEGORY_PHYSICIAN,
                            name: 'Behandler',
                            typ: StepTyp.Normal,
                            completed: false,
                            draft: false,
                            active: false,
                            clickable: false,
                        });
                    }

                    steps.push({
                        id: ID_APPOINTMENT_SELECTION,
                        name: 'Termin buchen',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                    steps.push({
                        id: ID_BOOKING,
                        name: 'Termin bestätigen',
                        typ: StepTyp.Normal,
                        completed: false,
                        draft: false,
                        active: false,
                        clickable: false,
                    });
                }
            } else {
                if (props.newAccountHashParams) {
                    /* Termin / Reigistrierung */
                    if (journeyResp !== undefined && journeyResp?.departments?.length > 0) {
                        /* Kein Termin buchen wenn an einem CheckInDesk -> wpName kommt mit -> Soforttermin */
                        if (isCheckInWorkplace()) {
                            steps.push({
                                id: ID_CATEGORY_DEPARTMENT,
                                name: 'Fachrichtung',
                                typ: StepTyp.Normal,
                                completed: false,
                                draft: false,
                                active: false,
                                clickable: true,
                            });
                            steps.push({
                                id: ID_CATEGORY_REASON,
                                name: 'Besuchsgrund',
                                typ: StepTyp.Normal,
                                completed: false,
                                draft: false,
                                active: false,
                                clickable: false,
                            });
                            if (!apstate.Appointment?.skipBehandlersuche) {
                                steps.push({
                                    id: ID_CATEGORY_PHYSICIAN,
                                    name: 'Behandler',
                                    typ: StepTyp.Normal,
                                    completed: false,
                                    draft: false,
                                    active: false,
                                    clickable: false,
                                });
                            }

                            steps.push({
                                id: ID_PERSONALDATA,
                                name: 'Daten eingeben',
                                typ: StepTyp.Normal,
                                completed: false,
                                draft: false,
                                active: false,
                                clickable: false,
                            });
                            steps.push({
                                id: ID_BOOKING,
                                name: 'Termin bestätigen',
                                typ: StepTyp.Normal,
                                completed: false,
                                draft: false,
                                active: false,
                                clickable: false,
                            });
                            /* Ohne CheckInDesk, wpName kommt nicht mit -> Buchung von daheim -> Termin buchen muss angezeigt werden */
                        } else {
                            /* physicans and timeslots in one step */
                            if (isExtendedPhysicians()) {
                                steps.push({
                                    id: ID_CATEGORY_DEPARTMENT,
                                    name: 'Fachrichtung',
                                    typ: StepTyp.Normal,
                                    completed: false,
                                    draft: false,
                                    active: false,
                                    clickable: true,
                                });
                                steps.push({
                                    id: ID_CATEGORY_REASON,
                                    name: 'Besuchsgrund',
                                    typ: StepTyp.Normal,
                                    completed: false,
                                    draft: false,
                                    active: false,
                                    clickable: false,
                                });
                                steps.push({
                                    id: ID_APPOINTMENT_SELECTION_WITH_PHYSICIAN,
                                    name: 'Termin buchen',
                                    typ: StepTyp.Normal,
                                    completed: false,
                                    draft: false,
                                    active: false,
                                    clickable: false,
                                });
                                steps.push({
                                    id: ID_PERSONALDATA,
                                    name: 'Daten eingeben',
                                    typ: StepTyp.Normal,
                                    completed: false,
                                    draft: false,
                                    active: false,
                                    clickable: false,
                                });
                                steps.push({
                                    id: ID_BOOKING,
                                    name: 'Termin bestätigen',
                                    typ: StepTyp.Normal,
                                    completed: false,
                                    draft: false,
                                    active: false,
                                    clickable: false,
                                });
                                /* physicians and timeslots in seperated steps*/
                            } else {
                                steps.push({
                                    id: ID_CATEGORY_DEPARTMENT,
                                    name: 'Fachrichtung',
                                    typ: StepTyp.Normal,
                                    completed: false,
                                    draft: false,
                                    active: false,
                                    clickable: true,
                                });
                                steps.push({
                                    id: ID_CATEGORY_REASON,
                                    name: 'Besuchsgrund',
                                    typ: StepTyp.Normal,
                                    completed: false,
                                    draft: false,
                                    active: false,
                                    clickable: false,
                                });
                                if (!apstate.Appointment?.skipBehandlersuche) {
                                    steps.push({
                                        id: ID_CATEGORY_PHYSICIAN,
                                        name: 'Behandler',
                                        typ: StepTyp.Normal,
                                        completed: false,
                                        draft: false,
                                        active: false,
                                        clickable: false,
                                    });
                                }
                                steps.push({
                                    id: ID_APPOINTMENT_SELECTION,
                                    name: 'Termin buchen',
                                    typ: StepTyp.Normal,
                                    completed: false,
                                    draft: false,
                                    active: false,
                                    clickable: false,
                                });
                                steps.push({
                                    id: ID_PERSONALDATA,
                                    name: 'Daten eingeben',
                                    typ: StepTyp.Normal,
                                    completed: false,
                                    draft: false,
                                    active: false,
                                    clickable: false,
                                });
                                steps.push({
                                    id: ID_BOOKING,
                                    name: 'Termin bestätigen',
                                    typ: StepTyp.Normal,
                                    completed: false,
                                    draft: false,
                                    active: false,
                                    clickable: false,
                                });
                            }
                        }
                    } else {
                        steps.push({
                            id: ID_REGISTRATION,
                            name: 'Registrierung',
                            typ: StepTyp.Normal,
                            completed: false,
                            draft: false,
                            active: true,
                            clickable: true,
                        });
                    }
                }
            }
        }

        setAllSteps(steps);
    };

    const getContent = (step?: number) => {
        let journeyResp = props.journeyResponse;
        if (journeyData !== undefined) {
            journeyResp = journeyData;
        }

        let stepContent = activeStep;
        if (step !== undefined) {
            stepContent = step;
        }

        allowMessages();

        conState.done = true;
        if (allSteps.length <= 0) {
            conState.done = false;
            return null;
        }
        if (isRegisteredUser()) {
            if (allSteps.length > 0) {
                if (allSteps[stepContent].id === ID_VIDEO) {
                    // forbidMessages();
                    return <VideochatWL checkInVariant={true} onVideoHangup={handleOnVideoHangup} />;
                } else if (allSteps[stepContent].id === ID_MYDATA) {
                    return (
                        <LeftDivCheckIn>
                            <AppointmentPersonalDataWithCardRead
                                showCardReadButton={isCheckInWorkplace()}
                                showBackButton={false}
                                showCancelButton={false}
                                showNextButton={false}
                                onCardReadClick={handleOnCardReadClick}
                                handleNextWithActiveStep={handlePersonalDataNextAfterCardRead}
                            />
                        </LeftDivCheckIn>
                    );
                } else if (allSteps[stepContent].id === ID_MYAPPOINTMENTS) {
                    if (updateEnrollmentRequest) {
                        updateEnrollment();
                        setUpdateEnrollmentRequest(false);
                    }

                    return (
                        <LeftDivCheckIn>
                            <TermineCheckIn
                                showNextButton={false}
                                currentActiveStep={1}
                                handleNextWithActiveStep={handleMyAppointmentNext}
                            />
                        </LeftDivCheckIn>
                    );
                } else if (allSteps[stepContent].id === ID_UPLOAD) {
                    return (
                        <LeftDivCheckIn>
                            <Upload
                                showNextButton={isCheckInWorkplace() ? true : false}
                                nextButtonEnabled={true}
                                currentActiveStep={activeStep}
                                handleNextWithActiveStep={handleUploadNext}
                            />
                        </LeftDivCheckIn>
                    );
                } else if (allSteps[stepContent].id === ID_ROUTE) {
                    return (
                        <LeftDivCheckIn>
                            <IFrameInternal
                                src={allSteps[stepContent].url ? allSteps[stepContent].url : ''}
                                name={'Lageplan'}
                                height={getRestHeight()}
                            />
                        </LeftDivCheckIn>
                    );
                } else if (allSteps[stepContent].id === ID_FINALCHECKIN) {
                    return <FinalCheckIn handleButtonClick={handleFinalCheckInClick} />;
                } else {
                    return <LeftDivCheckIn>{showQuestionnaireEntries(stepContent)}</LeftDivCheckIn>;
                }
            }
        } else if (false && props.isPhysicianLogin) {
            /* Als Arzt angemeldet -> will neuen Account fuer Patienten anlegen */
            /*
            if (stepContent === 0) {
                if (journeyResp !== undefined && journeyResp?.departments?.length > 0) {
                    return (
                        <AppointmentCategoryDepartment
                            departments={journeyResp.departments}
                            handleDepartmentClick={handleDepartmentClick}
                        />
                    );
                } else if (props.useLoginContext) {
                    return (
                        <AppointmentCategoryDepartment
                            departments={state.departments}
                            handleDepartmentClick={handleDepartmentClick}
                        />
                    );
                }
            } else if (allSteps[stepContent].id === ID_CATEGORY_REASON) {
                return (
                    <AppointmentCategoryReason
                        departments={tmstate.selectedDepartment}
                        hints={tmstate.journeyData.hints}
                        handleReasonClick={handleReasonClick}
                    />
                );
            } else if (allSteps[stepContent].id === ID_CATEGORY_PHYSICIAN) {
                return (
                    <AppointmentCategoryPhysician
                        department={tmstate.selectedDepartment.name}
                        reason={tmstate.selectedReason}
                        handlePhysicianClick={handlePhysicianClick}
                        currentActiveStep={2}
                    />
                );
            } else if (allSteps[stepContent].id === ID_APPOINTMENT_SELECTION) {
                return (
                    <LeftDivCheckIn>
                        <AppointmentCalendarWithHeadline
                            handleTimelotClick={handleTimeslotClick}
                            currentActiveStep={3}
                        />
                    </LeftDivCheckIn>
                );
            } else if (allSteps[stepContent].id === ID_PERSONALDATA) {
                return (
                    <LeftDivCheckIn>
                        <AppointmentPersonalDataWithLogin
                            showLogin={showAccountInAnmeldeworkflow()}
                            showRegistrationCbx={showAccountInAnmeldeworkflow()}
                            onLoginClick={handleLoginIconClick}
                            handleNextWithActiveStep={handlePersonalDataNext}
                            showCancelButton={false}
                            showBackButton={false}
                            isPhysPat={true}
                            currentActiveStep={4}
                        />
                    </LeftDivCheckIn>
                );
            } else if (allSteps[stepContent].id === ID_BOOKING) {
                return (
                    <LeftDivCheckIn>
                        <BookingAppointment
                            reason={
                                props.newAccountHashParams?.reason ? props.newAccountHashParams?.reason : 'ANMELDKARD'
                            }
                            reasonGroupText="Bemerkung"
                            handleBookingClick={handleBookingClick}
                            handleCancelClick={handleBookingCancelClick}
                            currentActiveStep={5}
                            useLoginContext={props.useLoginContext}
                            isPhysicianLogin={props.isPhysicianLogin}
                            newAccountHashParam={props.newAccountHashParams}
                        />
                    </LeftDivCheckIn>
                );
            } else {
                return showQuestionnaireEntries(stepContent);
            }
            */
        } else {
            /* Kein Termin buchen wenn an einem CheckInDesk -> wpName kommt mit -> Soforttermin */
            if (isCheckInWorkplace()) {
                if (stepContent === 0) {
                    if (journeyResp !== undefined && journeyResp?.departments?.length > 0) {
                        return (
                            <AppointmentCategoryDepartment
                                departments={journeyResp.departments}
                                handleDepartmentClick={handleDepartmentClick}
                            />
                        );
                    } else {
                        return (
                            <NeuerAccount
                                {...props.newAccountHashParams}
                                handleFinishClick={handleRegistrationFinished}
                            />
                        );
                    }
                } else if (allSteps[stepContent].id === ID_CATEGORY_REASON) {
                    return (
                        <AppointmentCategoryReason
                            departments={tmstate.selectedDepartment}
                            hints={tmstate.journeyData.hints}
                            handleReasonClick={handleReasonClick}
                        />
                    );
                } else if (allSteps[stepContent].id === ID_CATEGORY_PHYSICIAN) {
                    return (
                        <AppointmentCategoryPhysician
                            department={tmstate.selectedDepartment.name}
                            reason={tmstate.selectedReason}
                            handlePhysicianClick={handlePhysicianClick}
                            currentActiveStep={2}
                        />
                    );
                } else if (allSteps[stepContent].id === ID_PERSONALDATA) {
                    return (
                        <LeftDivCheckIn>
                            <AppointmentPersonalDataWithLogin
                                showLogin={showAccountInAnmeldeworkflow()}
                                showRegistrationCbx={showAccountInAnmeldeworkflow()}
                                onLoginClick={handleLoginIconClick}
                                handleNextWithActiveStep={handlePersonalDataNext}
                                showCancelButton={false}
                                showBackButton={false}
                                nextButtonEnabled={true}
                                isPhysPat={false}
                                currentActiveStep={3}
                            />
                        </LeftDivCheckIn>
                    );
                } else if (allSteps[stepContent].id === ID_BOOKING) {
                    return (
                        <LeftDivCheckIn>
                            <BookingAppointment
                                reason={
                                    props.newAccountHashParams?.reason
                                        ? props.newAccountHashParams?.reason
                                        : 'ANMELDKARD'
                                }
                                reasonGroupText="Bemerkung"
                                handleBookingClick={handleBookingClick}
                                handleCancelClick={handleBookingCancelClick}
                                currentActiveStep={4}
                                useLoginContext={props.useLoginContext}
                                newAccountHashParam={props.newAccountHashParams}
                            />
                        </LeftDivCheckIn>
                    );
                } else {
                    return showQuestionnaireEntries(stepContent);
                }
            } else {
                if (isExtendedPhysicians()) {
                    /* Terminauswahl und Arztauswahl in einem Step */
                    if (allSteps[stepContent].id == ID_CATEGORY_LOCATION) {
                        return <AppointmentCategoryLocation handleLocationClick={handleLocationClick} />;
                    } else if (allSteps[stepContent].id == ID_CATEGORY_DEPARTMENT) {
                        if (journeyResp !== undefined && journeyResp?.departments?.length > 0) {
                            return (
                                <AppointmentCategoryDepartment
                                    departments={journeyResp.departments}
                                    handleDepartmentClick={handleDepartmentClick}
                                />
                            );
                        } else if (props.useLoginContext) {
                            return (
                                <AppointmentCategoryDepartment
                                    departments={state.departments}
                                    handleDepartmentClick={handleDepartmentClick}
                                />
                            );
                        } else {
                            return (
                                <NeuerAccount
                                    {...props.newAccountHashParams}
                                    handleFinishClick={handleRegistrationFinished}
                                />
                            );
                        }
                    } else if (allSteps[stepContent].id === ID_CATEGORY_REASON) {
                        return (
                            <AppointmentCategoryReason
                                departments={tmstate.selectedDepartment}
                                hints={tmstate.journeyData.hints}
                                handleReasonClick={handleReasonClick}
                            />
                        );
                    } else if (allSteps[stepContent].id === ID_APPOINTMENT_SELECTION_WITH_PHYSICIAN) {
                        return (
                            <AppointmentCategoryExtendedPhysician
                                department={tmstate.selectedDepartment.name}
                                reason={tmstate.selectedReason}
                                handleTimeslotClick={handleTimeslotClick}
                                newAccountHashParam={props.newAccountHashParams}
                                currentActiveStep={stepContent}
                            />
                        );
                    } else if (allSteps[stepContent].id === ID_PERSONALDATA) {
                        return (
                            <LeftDivCheckIn>
                                <AppointmentPersonalDataWithLogin
                                    showLogin={showAccountInAnmeldeworkflow()}
                                    showRegistrationCbx={showAccountInAnmeldeworkflow()}
                                    showPrivacyPolicyCbx={props.isPhysicianLogin ? false : true}
                                    onLoginClick={handleLoginIconClick}
                                    handleNextWithActiveStep={handlePersonalDataNext}
                                    showCancelButton={false}
                                    showBackButton={false}
                                    isPhysPat={props.isPhysicianLogin}
                                    currentActiveStep={stepContent}
                                />
                            </LeftDivCheckIn>
                        );
                    } else if (allSteps[stepContent].id === ID_BOOKING) {
                        return (
                            <LeftDivCheckIn>
                                <BookingAppointment
                                    reason={
                                        props.newAccountHashParams?.reason
                                            ? props.newAccountHashParams?.reason
                                            : 'ANMELDKARD'
                                    }
                                    reasonGroupText="Bemerkung"
                                    handleBookingClick={handleBookingClick}
                                    handleCancelClick={handleBookingCancelClick}
                                    currentActiveStep={stepContent}
                                    useLoginContext={props.useLoginContext}
                                    isPhysicianLogin={props.isPhysicianLogin}
                                    newAccountHashParam={props.newAccountHashParams}
                                />
                            </LeftDivCheckIn>
                        );
                    } else {
                        return showQuestionnaireEntries(stepContent);
                    }
                } else {
                    /* Ohne CheckInDesk, wpName kommt nicht mit -> Buchung von daheim -> Termin buchen muss angezeigt werden */
                    if (allSteps[stepContent].id == ID_CATEGORY_LOCATION) {
                        return <AppointmentCategoryLocation handleLocationClick={handleLocationClick} />;
                    } else if (allSteps[stepContent].id == ID_CATEGORY_DEPARTMENT) {
                        if (journeyResp !== undefined && journeyResp?.departments?.length > 0) {
                            return (
                                <AppointmentCategoryDepartment
                                    departments={journeyResp.departments}
                                    handleDepartmentClick={handleDepartmentClick}
                                />
                            );
                        } else if (props.useLoginContext) {
                            return (
                                <AppointmentCategoryDepartment
                                    departments={state.departments}
                                    handleDepartmentClick={handleDepartmentClick}
                                />
                            );
                        } else {
                            return (
                                <NeuerAccount
                                    {...props.newAccountHashParams}
                                    handleFinishClick={handleRegistrationFinished}
                                />
                            );
                        }
                    } else if (allSteps[stepContent].id === ID_CATEGORY_REASON) {
                        return (
                            <AppointmentCategoryReason
                                departments={tmstate.selectedDepartment}
                                hints={tmstate.journeyData.hints}
                                handleReasonClick={handleReasonClick}
                            />
                        );
                    } else if (allSteps[stepContent].id === ID_CATEGORY_PHYSICIAN) {
                        return (
                            <AppointmentCategoryPhysician
                                department={tmstate.selectedDepartment.name}
                                reason={tmstate.selectedReason}
                                handlePhysicianClick={handlePhysicianClick}
                                currentActiveStep={stepContent}
                            />
                        );
                    } else if (allSteps[stepContent].id === ID_APPOINTMENT_SELECTION) {
                        return (
                            <LeftDivCheckIn>
                                <AppointmentCalendarWithHeadline
                                    handleTimelotClick={handleTimeslotClick}
                                    currentActiveStep={stepContent}
                                />
                            </LeftDivCheckIn>
                        );
                    } else if (allSteps[stepContent].id === ID_PERSONALDATA) {
                        return (
                            <LeftDivCheckIn>
                                <AppointmentPersonalDataWithLogin
                                    showLogin={showAccountInAnmeldeworkflow()}
                                    showRegistrationCbx={showAccountInAnmeldeworkflow()}
                                    showPrivacyPolicyCbx={props.isPhysicianLogin ? false : true}
                                    onLoginClick={handleLoginIconClick}
                                    handleNextWithActiveStep={handlePersonalDataNext}
                                    showCancelButton={false}
                                    showBackButton={false}
                                    isPhysPat={props.isPhysicianLogin}
                                    currentActiveStep={stepContent}
                                />
                            </LeftDivCheckIn>
                        );
                    } else if (allSteps[stepContent].id === ID_BOOKING) {
                        return (
                            <LeftDivCheckIn>
                                <BookingAppointment
                                    reason={
                                        props.newAccountHashParams?.reason
                                            ? props.newAccountHashParams?.reason
                                            : 'ANMELDKARD'
                                    }
                                    reasonGroupText="Bemerkung"
                                    handleBookingClick={handleBookingClick}
                                    handleCancelClick={handleBookingCancelClick}
                                    currentActiveStep={stepContent}
                                    useLoginContext={props.useLoginContext}
                                    isPhysicianLogin={props.isPhysicianLogin}
                                    newAccountHashParam={props.newAccountHashParams}
                                />
                            </LeftDivCheckIn>
                        );
                    } else {
                        return showQuestionnaireEntries(stepContent);
                    }
                }
            }
        }

        conState.done = false;
        return null;
    };

    const inMeineDatan = () => {
        if (allSteps[activeStep]?.id == ID_MYDATA || allSteps[activeStep]?.id == ID_PERSONALDATA) {
            return true;
        }
        return false;
    };

    const contentToShow = () => {
        if (showLoadingSpinner) {
            return (
                <div>
                    <LoadingSpinnerWithText loadingText="" />
                </div>
            );
        } else if (inMeineDatan() && (isLoggedIn || (isRegisteredUser() && !isCheckInWorkplace()))) {
            /* we must unmount the PersonalData Page with this dummy LoadingSpinner to be sure that the personal data is filled in correctly when tmstate.personalData is set with values */
            if (tmstate.personalData.lastName.length <= 0) {
                return (
                    <div>
                        <LoadingSpinnerWithText loadingText="" />
                    </div>
                );
            } else {
                return getContent();
            }
        } else if (isCheckInWorkplace()) {
            /* we must unmount the PersonalData Page with this dummy LoadingSpinner to be sure that the personal data is filled in correctly when tmstate.personalData is set with values */
            if (
                cardReadClickedInPersData ||
                !initPageDone ||
                (isRegisteredUser() && tmstate.personalData.lastName.length <= 0)
            ) {
                return (
                    <div>
                        <LoadingSpinnerWithText loadingText="" />
                    </div>
                );
            } else {
                // return getContent();
                return <div>{activeContent}</div>;
            }
        } else {
            return <div>{activeContent}</div>;
        }
    };

    interface INavbarAndStepper {
        fullscreenMode: boolean;
    }
    const NavbarAndStepper = (props: INavbarAndStepper) => {
        if (props.fullscreenMode) {
            return null;
        } else {
            return (
                <StickyStepperWithNavbar backgroundColor="white" zIndex={30}>
                    <NavbarCheckInWL
                        onLogout={handleLogout}
                        onProfileClick={handleProfileClick}
                        showAvatarIcon={showAvatarIcon()}
                        showLoginIcon={showLoginIcon()}
                        onLoginClick={handleLoginIconClick}
                        wpName={isCheckInWorkplace() ? checkinstate.wpName : ''}
                    />

                    <CenterDiv id="steppercheckinparent">
                        <StepperCheckIn
                            steps={allSteps}
                            backgroundColor={'white'}
                            handleStepClick={handleStepClick}
                            stepperOrientation={StepperOrientation.Horizontal}
                            labelOrientation={StepperLabelOrientation.Bottom}
                            paddingTop="5px"
                            paddingBottom="30px"
                        />
                    </CenterDiv>
                </StickyStepperWithNavbar>
            );
        }
    };

    /**
     * --------------------------------------------------------
     * ############### END STEPS AND CONTENT ##################
     * --------------------------------------------------------
     */

    const impressum = getImpressum();

    const showImpressum = () => {
        if (props.useLoginContext) {
            return false;
        }
        if (isFullscreenMode()) {
            return false;
        }
        return true;
    };

    return (
        <FullScreenImpressum show={showImpressum()} impressum={impressum}>
            <Container fluid>
                {props.useLoginContext ? (
                    <StickyStepperDiv>
                        <CenterDiv id="steppercheckinparent">
                            <StepperCheckIn
                                steps={allSteps}
                                backgroundColor={'white'}
                                handleStepClick={handleStepClick}
                                stepperOrientation={StepperOrientation.Horizontal}
                                labelOrientation={StepperLabelOrientation.Bottom}
                                paddingTop="5px"
                                paddingBottom="30px"
                            />
                        </CenterDiv>
                    </StickyStepperDiv>
                ) : (
                    <NavbarAndStepper fullscreenMode={isFullscreenMode()} />
                )}
                {contentToShow()}

                <PDFModal
                    show={pdfModalShow.show}
                    onHide={() => handleClosePDFModal()}
                    modalTitle={pdfModalShow.modalTitle}
                    pdfData={pdfModalShow.pdfData}
                    isSignable={pdfModalShow.isSignable}
                    isSigned={pdfModalShow.isSigned}
                    diagnosticReportData={pdfModalShow.diagnosticReportData}
                    fullScreen={true}
                />
                <LoginCardModal
                    show={loginModalShow.show}
                    onHide={() => handleCloseLoginModal()}
                    onLoginClick={handleLogin}
                />
                <TelephoneModal
                    show={showTelephoneModal}
                    callProps={callProps}
                    onCallAccept={() => onTelephoneCallAccept()}
                    onCallOff={() => onTelephoneCallOff()}
                    onCallHide={() => onTelephoneHide()}
                />
            </Container>
        </FullScreenImpressum>
    );
};

export default CheckInWL;
